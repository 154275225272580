import OrganizationFilter from '../../../utils/OrganizationFilter';
import CSVDownload from '../../actions/CSVDownload';
import JSONFileDownload from '../../actions/JSONFileDownload';
import { adminAccessBanner } from '../../misc/AdminAccessBanner';
import { useUser } from '../../../hooks/useUser';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';


const DepositHeader = ({ props }) => {
  const { deposits, organizationVisibility, setOrganizationVisibility} = props;

  const { user } = useUser();
  const [hover, setHover] = useState(false);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center">
          <span className="text-2xl text-gray-100 font-montserratM pr-4">Deposits</span>
          <span>{adminAccessBanner(user)}</span>
        </div>
        <OrganizationFilter
          hidden={!user.groups.includes('superadmins')}
          organizationVisibility={organizationVisibility}
          setOrganizationVisibility={setOrganizationVisibility}
        />
        <div className="flex space-x-2">
          <InformationCircleIcon className="h-5 w-5 mt-1 mr-1 text-gray-500"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
          {hover && (
            <div className="absolute right-10 m-10 bg-white text-black p-2 rounded shadow-lg w-max">
              Downloading this type of document in CSV format may result in data loss
            </div>
          )}
          <CSVDownload title="deposits" documents={deposits} />
          <JSONFileDownload title="deposits" data={deposits} />
        </div>
      </div>
      <span className="opacity-65 font-semibold">{user?.organization}</span>
    </>
    
  );
};

export default DepositHeader;
