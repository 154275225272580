import { useSelector, useDispatch } from 'react-redux';
import * as ReactDOM from 'react-dom';
import { ReactPortal } from 'react';
import { hideModal } from '../../store/modal';

export function Modal(): ReactPortal {
  const dispatch = useDispatch();
  const mount = useSelector((state: any) => state.modal.mount);
  const isVisible = useSelector((state: any) => state.modal.show);
  const ActiveComponent = useSelector((state: any) => state.modal.current);

  if (!mount || !isVisible) {
    return null;
  }
  return ReactDOM.createPortal(
    <div
      className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-start z-[80]"
      onClick={() => dispatch(hideModal())}
    >
      <div className="pt-2 sm:pt-12" onClick={e => e.stopPropagation()}>
        <ActiveComponent />
      </div>
    </div>,
    mount
  );
}

export default Modal;
