import axiosRequest from './axios';
import { urls } from '../config';
import {
  CREATE_TRANSACTION,
  GET_ONE_TX,
  GET_TRANSACTIONS,
  TX_APPROVE_OR_REJECT,
} from './constants';
import { toast } from 'react-hot-toast';

// ACTION CREATORS
const newTransaction = data => ({ type: CREATE_TRANSACTION, data });
const setOneTransaction = data => ({ type: GET_ONE_TX, data });
const setTransactions = data => ({ type: GET_TRANSACTIONS, data });
const approveOrRejectTx = data => ({ type: TX_APPROVE_OR_REJECT, data });
// THUNKS

export const createNewTransaction = data => async dispatch => {
  data.amount = data.amount.toString();
  const res = await axiosRequest(`${urls.transactionUrl}/create`, {
    method: 'POST',
    data,
  });

  if (!res) return;
  if (res.status >= 300) return { error: res.data };

  await dispatch(newTransaction(res.data));
  return res.data;
};

export const getOneTransaction = (signal, txId) => async (dispatch, getState) => {
  const { user, role } = await getState()?.session;

  if (!user || !role) return;

  const res = await axiosRequest(`${urls.transactionUrl}/status/${txId}`, {
    signal,
    method: 'POST',
  });
  if (!res) return;
  const { data, status } = res;
  if (status >= 300) return { error: data };
  await dispatch(setOneTransaction(data));
  return data;
};

// SUPER ADMIN
// SUPER ADMIN
// SUPER ADMIN

export const getTransactionsAsSuperAdmin = signal => async (dispatch, getState) => {
  const { role } = await getState()?.session;
  if (!role || role !== 'superadmins') return;

  const res = await axiosRequest(`${urls.superAdminUrl}/transactions`, { signal });
  if (!res) return;
  const { status, data } = res;

  if (status < 300 && data) {
    await dispatch(setTransactions(data));
    return data;
  } else {
    throw new Error('Error retrieving transactions.');
  }
};

// SUPER ADMIN
// SUPER ADMIN
// SUPER ADMIN

export const getTransactions = signal => async (dispatch, getState) => {
  const { user, role } = await getState()?.session;
  if (!user || !role) return;
  let res;
  if (role === 'admins') {
    res = await axiosRequest(`${urls.adminUrl}/transactions`, { signal });
  } else {
    res = await axiosRequest(urls.transactionUrl, { signal });
  }

  if (!res) return;
  const { status, data } = res;

  if (status < 300 && data) {
    await dispatch(setTransactions(data));
    return data;
  } else {
    throw new Error('Error retrieving transactions.');
  }
};

export const handleTxApprovalRejection = (action, id) => async (dispatch, getState) => {
  if (action !== 'approve' && action !== 'reject') return;

  const res = await axiosRequest(`${urls.transactionUrl}/${action}/${id}`, { method: 'POST' });

  if (!res) return;
  const { status, data } = res;
  if (data && status < 300) {
    await dispatch(approveOrRejectTx(data));
    return data;
  } else {
    toast.error(res.data);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    throw new Error('Error approving or rejecting transaction.');
  }
};

// REDUCER
const transactionReducer = (state = {}, action) => {
  const newState = { ...state };
  switch (action.type) {
    case CREATE_TRANSACTION: {
      return newState;
    }
    case GET_ONE_TX: {
      if (action.data) newState[action.data.txId] = action.data;
      return newState;
    }
    case GET_TRANSACTIONS: {
      if (action.data) action.data.forEach(tx => (newState[tx.txId] = tx));
      return newState;
    }
    case TX_APPROVE_OR_REJECT: {
      return newState;
    }
    default:
      return state;
  }
};

export default transactionReducer;
