import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getNotifications,
  markNotificationsAsSeen,
  unreadCount,
} from '../../../store/notifications';
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline';

function NotificationIcon({ unread }) {
  return (
    <div className="relative">
      <BellIcon className="w-6 hover:text-teal-400 text-white" />
      {unread > 0 && (
        <p className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white rounded-md px-1.5 py-1 text-xs leading-none text-center">
          {unread}
        </p>
      )}
    </div>
  );
}

function DropdownNotificationItems({ notifications }) {
  if (!notifications) {
    return <div className="text-sm text-white hover:cursor-default">No notifications</div>;
  }
  return notifications.map((notification, i) => (
    <li key={i} className="p-3 border text-white">
      <div className="float-left inline-flex text-sm items-center">
        {notification.urgent && <div>⭐</div>}
        {notification.seen || <div className="pl-1 text-red-500">⬤</div>}
      </div>
      <p className="text-xs text-gray-400">{new Date(notification.createdAt).toLocaleString()}</p>
      <p className="text-sm">{notification.message}</p>
    </li>
  ));
}

function DropdownMenu({ open, setOpen, notifications }) {
  if (!open) {
    return null;
  }
  // TODO: remove this `position: absolute`
  return (
    <div className="group overflow-y-auto max-h-[500px] overflow-x-hidden absolute z-30 bg-gradient-to-r from-slate-500 to-slate-700 shadow-2xl w-[500px] top-10 right-10 p-2 rounded">
      <button className="pb-2" onClick={() => setOpen(!open)} aria-label="close">
        <XMarkIcon className="w-5" />
      </button>
      <ul className="text-right">
        <DropdownNotificationItems notifications={notifications} />
      </ul>
    </div>
  );
}

const Notifications = () => {
  const user = useSelector(state => state.session.user);
  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState(0);
  const [open, setOpen] = useState(false);

  // Set notifications
  useEffect(() => {
    if (!open) return;
    getNotifications(user.sub)
      .then(res => {
        if (!res || res.status >= 300) return;
        setNotifications(res.data);
        markNotificationsAsSeen(user.sub).then(() => setUnread(0));
      })
      .catch(err => console.error(err));
  }, [user.sub, open]);

  // Get unread count on page load
  useEffect(() => {
    unreadCount(user.sub)
      .then(res => {
        if (!res || res.status >= 300) return;
        setUnread(res.data);
      })
      .catch(err => console.error(err));
  }, [user.sub]);

  // Intermittently re-update unread message counter
  useEffect(() => {
    const interval = setInterval(() => {
      unreadCount(user.sub)
        .then(res => {
          if (!res || res.status >= 300) return;
          setUnread(res.data);
        })
        .catch(err => console.error(err));
    }, 10000);
    return () => clearInterval(interval);
  }, [user.sub]);

  return (
    <div className="hover:text-teal-400 text-right">
      <button onClick={() => setOpen(!open)}>
        <NotificationIcon unread={unread} />
      </button>
      <DropdownMenu open={open} setOpen={setOpen} notifications={notifications} />
    </div>
  );
};

export default Notifications;
