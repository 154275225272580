import { useEffect, useMemo, useState, ReactNode } from 'react';
import { toast } from 'react-hot-toast';
import { baseCard } from '../../../config/tailwind.classnames';
import { InformationCircleIcon, PencilIcon } from '@heroicons/react/24/outline';

import EmailVerification from './EmailVerification';
import { CopyToClipboard } from '../../../assets/icons/icons';
import { UsersServices } from '../../../services/users.services';
import { AxiosResponse } from 'axios';
import { UserContextInterface } from '../../../types/interfaces';

interface UserSettingsProps {
  userRef: UserContextInterface['userRef'];
}

const UserSettings = ({ userRef }: UserSettingsProps) => {
  const {
    current: { role },
  } = userRef;

  const [sub, setSub] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [webhookKey, setWebhookKey] = useState('');
  const [apiKey, setAPIKey] = useState('');

  const [editActive, setEditActive] = useState(false);
  const [editWebhookUrlActive, setWebhookUrlEditActive] = useState(false);

  const editNamesActive = useMemo(
    () => editActive && (role === 'superadmins' || role === 'admins'),
    [editActive, role],
  );
  const editWebUrlActive = useMemo(
    () => editWebhookUrlActive && role !== 'superadmins' && role !== 'admins',
    [editWebhookUrlActive, role],
  );

  const populateUser = () => {
    UsersServices.getUser()
      .then(user => {
        const { sub, familyName, givenName, webhookUrl, webhookKey, apiKey } = user;

        setSub(sub || '');
        setFirstName(givenName || '');
        setLastName(familyName || '');
        setWebhookUrl(webhookUrl || '');
        setWebhookKey(webhookKey || '');
        setAPIKey(apiKey || '');
      })
      .catch(err => {
        toast.error(`Failed to get Authenticated user\nStatus Code: ${err.status}`);
      });
  };

  useEffect(() => {
    populateUser();
  }, []);

  const submitChanges = async () => {
    // Trim any leading or trailing whitespace
    const firstNameTrimmed = firstName?.trim();
    const lastNameTrimmed = lastName?.trim();
    const webhookUrlTrimmed = webhookUrl?.trim();

    setFirstName(firstNameTrimmed);
    setLastName(lastNameTrimmed);
    setWebhookUrl(webhookUrlTrimmed);

    const onStart = () => {
      toast.loading('Working...');
    };
    const onSuccess = () => {
      toast.dismiss();
      setEditActive(false);
      setWebhookUrlEditActive(false);
    };
    const onError = (res: AxiosResponse) => {
      toast.error(`Failed to update user\nStatus Code: ${res.status}`);
    };
    const data = {
      givenName: firstNameTrimmed,
      familyName: lastNameTrimmed,
      webhookUrl: webhookUrlTrimmed,
    };
    UsersServices.updateUser({
      userId: sub,
      data,
      onStart,
      onSuccess,
      onError,
      userRef,
    });
  };

  return (
    <div className="grid md:grid-cols-5 xl:grid-cols-4">
      <div className="flex items-center justify-between pb-5 text-white md:col-span-3 md:col-start-2 xl:col-span-2 xl:col-start-2">
        <h1 className="text-2xl text-white">My Account</h1>
        {editActive || editWebhookUrlActive ? (
          <div className="flex space-x-4">
            <button
              onClick={submitChanges}
              className="px-4 py-2 bg-green-700 hover:bg-green-600 rounded"
            >
              <p className="text-sm font-medium leading-none text-white">Save Changes</p>
            </button>
            <button
              className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded"
              onClick={() => {
                setEditActive(false);
                setWebhookUrlEditActive(false);
                populateUser();
              }}
            >
              <p className="text-sm font-medium leading-none text-white">Cancel</p>
            </button>
          </div>
        ) : (
          <button
            className="px-4 py-2 bg-green-700 hover:bg-green-600 rounded"
            onClick={() => {
              setEditActive(true);
              setWebhookUrlEditActive(true);
            }}
          >
            <p className="text-sm font-medium leading-none text-white">Edit Info</p>
          </button>
        )}
      </div>

      <div
        className={`${baseCard} text-sm space-y-2 md:col-span-3 md:col-start-2 xl:col-span-2 xl:col-start-2`}
      >
        <div className="flex">
          <div className="text-gray-400 min-w-40">Sub</div>
          <div>{sub}</div>
        </div>
        <div className="flex">
          <div className="text-gray-400 min-w-40">First Name</div>
          {!editNamesActive ? (
            <div>{firstName}</div>
          ) : (
            <input
              type="text"
              value={firstName}
              autoComplete="off"
              data-lpignore="true"
              placeholder="Enter your first name"
              onChange={e => setFirstName(e.target.value)}
              className="w-96 rounded p-3 bg-gray-800 bg-opacity-70 text-right"
            />
          )}
        </div>
        <div className="flex">
          <div className="text-gray-400 min-w-40">Last Name</div>
          {!editNamesActive ? (
            <div>{lastName}</div>
          ) : (
            <input
              type="text"
              value={lastName}
              autoComplete="off"
              data-lpignore="true"
              placeholder="Enter your last name"
              onChange={e => setLastName(e.target.value)}
              className="w-96 rounded p-3 bg-gray-800 bg-opacity-70 text-right"
            />
          )}
        </div>
        <div className="flex">
          <div className="text-gray-400 min-w-40">Email</div>
          <EmailVerification />
        </div>
        <div className="flex justify-start">
          <div className="flex">
            <InformationOnHover
              title="Webhook URL"
              content={
                'Notifications about transactions can be sent as HTTP requests to a given URL.'
              }
            />
          </div>
          {!editWebUrlActive ? (
            <div className="flex items-center">
              <div className="text-gray-300">{webhookUrl || '(Optional)'}</div>
              {role !== 'superadmins' && role !== 'admins' && (
                <div className="pl-4">
                  <PencilIcon
                    className="h4 w-4 cursor-pointer"
                    onClick={() => setWebhookUrlEditActive(true)}
                  />
                </div>
              )}
            </div>
          ) : (
            <input
              type="text"
              value={webhookUrl}
              placeholder="Enter your webhook URL"
              onChange={e => setWebhookUrl(e.target.value)}
              className="w-96 rounded px-2 py-1 bg-gray-800 bg-opacity-70 focus:ring ring-blue-500"
            />
          )}
        </div>
        <div className="flex">
          <InformationOnHover
            title="Webhook Key"
            content="Use this 32-byte key to decode webhooks with AES."
          />
          <HiddenValue label="Webhook key" value={webhookKey} />
        </div>
        <div className="flex">
          <InformationOnHover
            title="API Key"
            content={
              <div>
                <span>
                  To use the Etana Digital API, include this key as a header in each HTTP request:
                </span>
                <span className="flex justify-center">
                  <code className="bg-gray-300 px-1 py-0.5 max-w-fit">X-API-Key: [API KEY]</code>
                </span>
              </div>
            }
          />
          <HiddenValue label="API key" value={apiKey} />
        </div>
      </div>
    </div>
  );
};

type HiddenValueParams = { label: string; value: string };

function HiddenValue({ label, value }: HiddenValueParams) {
  const [showValue, setShowValue] = useState(false);
  const shown = showValue ? value : '*'.repeat(30);
  return (
    <div className="flex text-xs text-gray-400 space-x-4">
      <span className="md:min-w-64">{shown}</span>
      <span className="flex space-x-1">
        <CopyToClipboard label={label} text={value} />
        <div
          onClick={() => setShowValue(cur => !cur)}
          className="text-gray-400 hover:text-blue-300 cursor-pointer text-xs"
        >
          {showValue ? 'Hide' : 'Show'}
        </div>
      </span>
    </div>
  );
}

type InformationOnHoverParams = { title: string; content: string | ReactNode };

function InformationOnHover({ title, content }: InformationOnHoverParams) {
  const [hover, setHover] = useState(false);
  return (
    <div className="flex items-center space-x-2 text-gray-400 min-w-40">
      <span>{title}</span>
      <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <InformationCircleIcon className="w-5 text-blue-500 opacity-70" />
        <div
          className={
            hover ? 'z-10 absolute bg-white text-black text-xs p-2 rounded shadow-md' : 'hidden'
          }
        >
          {content}
        </div>
      </div>
    </div>
  );
}

export default UserSettings;
