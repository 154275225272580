import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../../../store/modal';
import Select from 'react-select';
import { base } from '../../../../config/select.styles';
import { getManagers } from '../../../../store/users';
import {
  adminAddManagerToWallet,
  removeManagerFromExistingWallet,
  getWalletById,
  addManagerToExistingWallet,
} from '../../../../store/wallets';
import { toast } from 'react-hot-toast';
import { Loading } from '../../../../assets/icons/loading';

const ManageManagers = ({ id, managers }) => {
  const [manageStatus, setManageStatus] = useState('add');
  const [chosenManager, setChosenManager] = useState('');
  const [options, setOptions] = useState([]);
  const { user } = useSelector(state => state.session);
  const orgManagers = useSelector(state => state?.users && state.users.managers);
  const dispatch = useDispatch();
  
  function changeManageStatus(status) {
    setManageStatus(status);
  }
  
  const handleSelect = (selectedOptions) => {
    const selectedManagers = selectedOptions?.map(opt => opt.value) ?? [];
    setChosenManager(selectedManagers);
  };
  
  function handleSubmit(e) {
    const controller = new AbortController();
    e.preventDefault();

    toast.dismiss();
    if (!chosenManager) {
      toast.error(`Please choose a manager to ${manageStatus}.`);
      return;
    }
    
    const toastLoad = toast.loading('Working...');

    if (manageStatus === 'add') {
      const addFunc =
        user.groups.includes('admins') || user.groups.includes('superadmins')
          ? adminAddManagerToWallet
          : addManagerToExistingWallet;
      dispatch(addFunc(id, chosenManager))
        .then(res => {
          toast.dismiss(toastLoad);
          if (res.error) {
            toast.error(`Error adding manager to wallet: ${res.error}`);
            return;
          }
          toast.success('Manager added.');
          dispatch(getWalletById(controller.signal, id));
        })
        .catch(err => {
          toast.dismiss(toastLoad);
          toast.error(`Error adding manager to wallet: ${err}`);
        });
    } else {
      dispatch(removeManagerFromExistingWallet(id, chosenManager))
        .then(res => {
          toast.dismiss(toastLoad);
          if (res.error) {
            toast.error(`Error removing manager from wallet: ${res.error}`);
            return;
          }
          toast.success('Manager removed.');
          dispatch(getWalletById(controller.signal, id));
        })
        .catch(err => {
          toast.dismiss(toastLoad);
          toast.error(`Error removing manager from wallet: ${err}`);
        });
    }
    dispatch(hideModal());
  }

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  useEffect(() => {
    if (manageStatus === 'add') {
      setOptions(
        orgManagers?.filter(mgr => !managers.includes(mgr)).map(mgr => ({ label: mgr, value: mgr }))
      );
      return;
    }
    setOptions(managers.map(mgr => ({ label: mgr, value: mgr })));
  }, [orgManagers, managers, manageStatus]);

  if (!managers) return <Loading />;

  return (
    <div className="text-white bg-gray-900 border border-green-500 bg-opacity-90 rounded-lg px-10 py-5 grid justify-center gap-1 max-w-[800px] w-[800px]">
      <div className="text-center text-sm pb-2">
        <span className="text-gray-400 pr-2">Wallet ID:</span>
        <span>{id}</span>
      </div>

      {/* "Select whether to add/remove managers" buttons */}
      <div className="flex justify-center text-sm pb-2">
        <div className="px-5">
          <button
            className="rounded bg-green-800 hover:bg-green-900 disabled:hidden py-2 px-1"
            disabled={!user.groups.includes('admins') && !user.groups.includes('investors')}
            onClick={() => changeManageStatus('add')}
          >
            Add Manager
          </button>
        </div>
        <div>
          <button
            className="rounded bg-red-600 hover:bg-red-700 disabled:hidden py-2 px-1"
            type="button"
            disabled={!user.groups.includes('admins')}
            onClick={() => changeManageStatus('remove')}
          >
            Remove Manager
          </button>
        </div>
      </div>

      {/*Managers list dropdown and submit buttons*/}
      <form onSubmit={handleSubmit}>
        <DropdownAndSubmitButtons
          handleSelect={handleSelect}
          options={options}
          chosenManager={chosenManager}
          manageStatus={manageStatus}
          dispatch={dispatch}
        />
      </form>
    </div>
  );
};

/**
 * @description - The form contents for the add/remove managers modal
 * @param manageStatus - Either 'add' or 'remove'
 * @param options - The options for the React-Select object
 * @param handleSelect - The hook for setting the selected manager
 * @param dispatch - The hook for dispatching to Redux
 * @returns {JSX.Element}
 * @constructor
 */
function DropdownAndSubmitButtons({ manageStatus, options, chosenManager, handleSelect,  dispatch }) {
  return (
    <>
      <div className="pb-32">
        <ManagerDropdown
          options={options}
          manageStatus={manageStatus}
          handleSelect={handleSelect}
          chosenManager={chosenManager}
        />
      </div>
      <div className="flex justify-center pt-2">
        <div className="pr-5">
          <button
            className="rounded bg-green-800 hover:bg-green-900 disabled:hidden py-2 px-2"
            type="submit"
            disabled={!chosenManager.length} // Disable submit if no managers are selected
          >
            Submit
          </button>
        </div>
        <div>
          <button
            className="rounded py-2 px-2 bg-gradient-to-br from-slate-600/25 to-slate-700/75 hover:bg-gradient-to-br hover:from-slate-500 hover:to-slate-600/50 hover:text-white transition-all duration-200 ease-in-out"
            onClick={() => dispatch(hideModal())}
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

/**
 * @description - The React Select dropdown menu containing manager options
 * @param manageStatus - Either 'add' or 'remove'
 * @param options - The options for the React-Select object
 * @param handleSelect - The hook for setting the selected manager
 * @returns {JSX.Element}
 * @constructor
 */
function ManagerDropdown({ manageStatus, options, chosenManager, handleSelect }) {
  
  const select = (
    <Select
      name="network"
      className="px-2 text-sm scroll-auto max-w-[700px] w-[700px]"
      maxMenuHeight={200}
      onChange={handleSelect}
      options={options}
      styles={base}
      isMulti
      closeMenuOnSelect={false}
      placeholder="Select a manager"
    />
  );
  if (manageStatus === 'add') {
    return (
      <div className="flex justify-center">
        {options?.length > 0 ? (
          select
        ) : (
          <span className="text-sm text-gray-400">No eligible managers to add.</span>
        )}
      </div>
    );
  }
  return (
    <div className="flex justify-center">
      {options?.length > 2 ? (
        select
      ) : (
        <span className="text-sm text-gray-400">
          There must be two managers at all times. <br /> Add an additional manager to remove one.
        </span>
      )}
    </div>
  );
}

export default ManageManagers;