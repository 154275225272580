import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import validator from 'validator';

import { toast } from 'react-hot-toast';
import Timer from '../../utils/Timer';

const ForgotPassword = ({setForgotPassword}) => {
  const [email, setEmail] = useState('');
  const [sentVerificationCode, setSentVerificationCode] = useState(false);
  const [fpVerification, setFPVerification] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [limitExceeded, setLimitExceeded] = useState(false);

  const errorInitialState = {
    email: false,
    code: false,
    newPassword: false,
    confirmNewPassword: false,
    pwMismatch: false,
    invalidPassword: false,
    amplify: false,
  };

  const [errors, setErrors] = useState(errorInitialState);

  useEffect(() => {
    setSentVerificationCode(false);
    setEmail('');
    setFPVerification('');
    setNewPassword('');
    setConfirmNewPassword('');
    setErrors(errorInitialState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSentVerificationCode(false);
    setEmail('');
  }, [limitExceeded]);

  const rememberedPassword = () => {
    setEmail('');
    setFPVerification('');
    setNewPassword('');
    setConfirmNewPassword('');
    setForgotPassword(false);
    setSentVerificationCode(false);
    setErrors(errorInitialState);
  };

  const checkValidPassword = password => {
    // eslint-disable-next-line no-useless-escape
    const pwRequirements =
      /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/g;
    return pwRequirements.test(password);
  };

  const handleForgotPassword = async e => {
    e.preventDefault();
    setErrors(errorInitialState);

    if (!email || !validator.isEmail(email)) {
      setErrors(errors => ({ ...errors, email: true }));
      return;
    }

    if (!sentVerificationCode) {
      // Verification code not yet sent to email address.
      try {
        toast.loading('Working...');
        await Auth.forgotPassword(email);
        setSentVerificationCode(true);
        setNewPassword('');
        setConfirmNewPassword('');
        toast.dismiss();
      } catch (e) {
        toast.dismiss();
        toast.error(e?.toString());

        if (e?.message === 'Attempt limit exceeded, please try after some time.') {
          setLimitExceeded(true);
        }

        console.log(`Error with sending verification code: ${e.message}`);
      }
    } else {
      // Verification code sent to email address.
      if (
        !newPassword ||
        !confirmNewPassword ||
        !fpVerification ||
        newPassword !== confirmNewPassword
      ) {
        if (!fpVerification) setErrors(errors => ({ ...errors, code: true }));
        if (!newPassword) setErrors(errors => ({ ...errors, newPassword: true }));
        if (!confirmNewPassword) setErrors(errors => ({ ...errors, confirmNewPassword: true }));
        if (newPassword !== confirmNewPassword) {
          setNewPassword('');
          setConfirmNewPassword('');
          setErrors(errors => ({ ...errors, pwMismatch: 'Passwords do not match.' }));
        }
      } else {
        if (checkValidPassword(newPassword)) {
          try {
            await Auth.forgotPasswordSubmit(email, fpVerification, newPassword);
            setSentVerificationCode(false);
            setForgotPassword(false);
            setErrors(errorInitialState);
            toast.success('Successful password reset and change. Please login.');
          } catch (err) {
            if (err?.message === 'Invalid verification code provided, please try again.') {
              setErrors(errors => ({
                ...errors,
                amplify: 'Invalid verification code or email provided.',
              }));
            } else {
              setErrors(errors => ({ ...errors, amplify: err?.message }));
            }
          }
        } else {
          // Invalid password complexity
          setErrors(errors => ({
            ...errors,
            invalidPassword:
              'Passwords must have the following: Minimum length of 14, number, lowercase, uppercase, and special character.',
          }));
        }
      }
    }
  };

  return (
    <div className="p-20 absolute bg-black bg-opacity-90 flex h-fit w-fit justify-center items-center text-white rounded-lg flex-col shadow-innerWhite">
      <div className="text-2xl mb-10">Forgot Your Password?</div>

      {sentVerificationCode ? (
        <form onSubmit={handleForgotPassword} className="flex flex-col items-center w-fit p-5">
          {errors.amplify ? (
            <p className="text-xs text-white bg-red-400 rounded bg-opacity-70 p-5">
              {errors.amplify}
            </p>
          ) : (
            <p className="text-xs text-green-300">
              If {email} is registered with us, you will receive a verification code shortly.
            </p>
          )}
          <div className="flex m-5 pt-5">
            <label className="w-128 text-sm">Verification Code</label>
            <input
              className={`${
                errors.code && 'border-red-500 border-2'
              } rounded w-full h-8 p-3 text-sm bg-gray-700`}
              type="text"
              value={fpVerification}
              placeholder="000000"
              autoComplete="off"
              onChange={e => setFPVerification(e.target.value)}
            />
          </div>
          {(errors.pwMismatch || errors.invalidPassword) && (
            <div className="flex ml-5 mr-5 justify-end text-right">
              <div className="w-full flex justify-end text-xs text-right text-red-500">
                {errors.pwMismatch || errors.invalidPassword}
              </div>
            </div>
          )}

          <div className="flex m-5">
            <label className="w-128 text-sm">New Password</label>
            <input
              className={`${
                (errors.newPassword || errors.pwMismatch) && 'border-red-500 border-2'
              } rounded w-full h-8 p-3 text-sm bg-gray-700`}
              type="password"
              name="newPassword"
              placeholder=""
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            ></input>
          </div>
          <div className="flex m-5">
            <label className="w-128 text-sm">Confirm New Password</label>
            <input
              className={`${
                (errors.confirmNewPassword || errors.pwMismatch) && 'border-2 border-red-500'
              } rounded w-full h-8 p-3 text-sm bg-gray-700`}
              type="password"
              name="confirmNewPassword"
              placeholder=""
              value={confirmNewPassword}
              onChange={e => setConfirmNewPassword(e.target.value)}
            ></input>
          </div>
          <button
            type="submit"
            className="w-64 bg-gray-600 text-white p-5 rounded mt-5 hover:opacity-80 bg-opacity-70 hover:bg-opacity-100"
          >
            Submit Verification Code
          </button>
        </form>
      ) : (
        <>
          {limitExceeded ? (
            <div className="shadow-innerWhite bg-gray-500 p-24 rounded-lg bg-opacity-60 flex justify-center items-center flex-col">
              <div className="text-xl mb-2">Request Limit Exceeded</div>
              <div className="text-sm mb-5">Please wait...</div>
              <Timer seconds={60 * 5} />
            </div>
          ) : (
            <form
              className="flex flex-col justify-center items-center px-5"
              onSubmit={handleForgotPassword}
            >
              <label className="mb-5">Please enter your registered email address:</label>
              {errors.email && (
                <p className="text-sm mb-2 text-red-500">Please enter a valid email address</p>
              )}
              <input
                className={`${
                  errors.email && 'border-2 border-red-500'
                } p-5 h-10 w-64 bg-gray-800 text-white rounded text-sm`}
                type="text"
                value={email}
                ref={input => input?.focus()}
                placeholder="email@domain.com"
                onChange={e => setEmail(e.target.value)}
              />
              <button
                type="submit"
                className="w-64 bg-gray-600 text-white p-5 rounded mt-5 text-sm bg-opacity-70 hover:bg-opacity-100"
              >
                Submit Verification Code
              </button>
            </form>
          )}
        </>
      )}
      <button
        className="cursor-pointer text-xs text-green-400 mt-5 hover:text-green-200"
        onClick={rememberedPassword}
      >
        No thanks, I remember my password now.
      </button>
    </div>
  );
};

export default ForgotPassword;
