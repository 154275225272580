export const SHOW_MODAL = 'modal/SHOW';
export const HIDE_MODAL = 'modal/HIDE';
export const SET_ACTIVE_COMPONENT = 'modal/ACTIVE_COMPONENT';
export const SET_MODAL = 'modal/SET';

export const SET_USER = 'session/SET_USER';
export const REMOVE_USER = 'session/REMOVE_USER';

export const GET_USERS = 'users/GET_USERS';
export const GET_MANAGERS = 'users/GET_MANAGERS';

export const GET_ONE_TX = 'transactions/GET_ONE_TRANSACTION';
export const CREATE_TRANSACTION = 'transactions/CREATE_TRANSACTION';
export const GET_TRANSACTIONS = 'transactions/GET_TRANSACTIONS';
export const TX_APPROVE_OR_REJECT = 'transactions/APPROVE_OR_REJECT';

export const CREATE_WALLET = 'wallets/CREATE_WALLET';
export const GET_WALLETS = 'wallets/GET_WALLETS';
export const GET_ONE_WALLET = 'wallets/GET_ONE_WALLET';
export const GET_BALANCES = 'wallets/GET_BALANCES';
export const ASSETS_WALLET = 'wallets/ASSETS_WALLET';

// --- ADMIN ACCESS --- //
export const ADD_MGR_TO_WALLET = 'admin/wallets/ADD_MGR_TO_WALLET';
export const REM_MGR_FM_WALLET = 'admin/wallets/REM_MGR_FM_WALLET';
export const ENABLE_WALLET = 'admin/wallets/ENABLE';
export const DISABLE_WALLET = 'admin/wallets/DISABLE';
export const ENABLE_ACCOUNT_IN_WALLET = 'admin/wallets/account/ENABLE';
export const DISABLE_ACCOUNT_IN_WALLET = 'admin/wallets/account/DISABLE';
