import axiosRequest from '../store/axios';
import { User, UserContextInterface } from '../types/interfaces';
import { AxiosResponse } from 'axios';

export class UsersServices {
  static updateUser({
    userId,
    data,
    onStart,
    onSuccess,
    onError,
    userRef,
 }: {
    userId: string;
    data: Partial<User>;
    onStart: () => void;
    onSuccess: () => void;
    onError: (res: AxiosResponse) => void;
    userRef: UserContextInterface['userRef'];
}) {
    axiosRequest(`/users/${userId}`, { method: 'PUT', data }).then(res => {
      onStart();
      if (res.status >= 300) {
        onError(res);
        return;
      }
      if (userRef.current.sub === userId) {
        userRef.current = Object.assign(userRef.current, data);
      }
      onSuccess();
    });
  }

  static getUser(): Promise<User> {
    return axiosRequest(`/users/current`, { method: 'GET' }).then(res => res.data);
  }
}