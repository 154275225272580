import { useContext } from 'react';
import UserContext from '../context/UserContext';
import { UserState } from '../types/interfaces';

const emptyUser = {
  groups: '',
};

export const useUser = (): { user: UserState } => {
  const { current: user } = useContext(UserContext)?.userRef || { current : {} };

  return {
    user: {
      ...emptyUser,
      ...user,
    } as UserState,
  }
}