import { Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';
import { toast } from 'react-hot-toast';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

const refreshSessionToken = async () => {
  const cookies = new Cookies();
  try {
    const user = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    user.refreshSession(session.getRefreshToken(), (err: any, session: CognitoUserSession) => {
      if (err) {
        toast.error(err);
        return;
      }
      const token = session.getAccessToken().getJwtToken();
      const maxAge = 60 * 60; // 1 hour (in seconds)
      cookies.remove('jwt_token', { path: '/' });
      cookies.set('jwt_token', token, { path: '/', maxAge });
    });
  } catch (e) {
    toast.error(`Error in refreshing session: ${e}`);
  }
};

export default refreshSessionToken;
