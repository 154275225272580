import { adminAccessBanner } from '../../misc/AdminAccessBanner';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

function WalletsHeader({ user }) {
  const navigate = useNavigate();
  const isManager = user?.groups.includes('investors');

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center space-x-4">
            <h1 className="text-2xl text-gray-100 font-montserratM">Wallets</h1>
            {adminAccessBanner(user)}
          </div>
        </div>
        <div>
          <button
            className={`rounded text-white hover:shadow-lg bg-gradient-to-br from-cyan-600 to-cyan-700/75 hover:bg-green-400 ${!isManager ? 'hidden' : ''}`}
            onClick={() => navigate('/dashboard/wallets/new')}
          >
            <span className="flex p-2 space-x-1">
              <PlusIcon className="w-6" />
              <span>Create New Wallet</span>
            </span>
          </button>
        </div>
      </div>
      <span className="opacity-65 font-semibold">{user?.organization}</span>
    </>
  );
}

export default WalletsHeader;
