import { useEffect, useState, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Timer from '../../utils/Timer';
import routes from './Routes';
import logo_icon from '../../assets/Etana/logo.svg';
import logo_text from '../../assets/Etana/text.svg';
import CustomLink from './CustomLink';
import Notifications from '../pages/profile/Notifications';
import SessionTimeout from '../pages/timeout/SessionTimeout';
import { removeUserSession } from '../../store/session';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';

export default function DashboardLayout({ sessionTime, width }) {
  const { role } = useSelector(state => state.session);
  if (!role) {
    return <div className="min-h-screen text-white w-full" />;
  }

  return (
    <div className="min-h-screen text-white w-full">
      <div className="flex min-h-screen h-full bg-gradient-to-b from-blue-950 to-black !w-screen">
        <SideBar sessionTime={sessionTime} width={width} />
        <div className="bg-gradient-to-bl from-[#18315F]-500 via-[#27447f]-500 via-[#27447f] to-[#111827]-900 p-2 md:p-6 w-screen">
          <Notifications />
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function SideBar({ sessionTime, width }) {
  const [minimized, setMinimized] = useState(width < 1130);
  const effectRun = useRef(null);
  const { role } = useSelector(state => state.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (effectRun.current && !role) {
      dispatch(removeUserSession());
    }
    effectRun.current = true;
  }, [dispatch, role]);

  return (
    <div
      className={`flex flex-col ${
        minimized
          ? '!min-w-[100px] !w-100 !max-w-[100px]'
          : '!w-[240px] !min-w-[240px] !max-w-[240px]'
      } transition-all`}
    >
      {/* Expand/minimize icon */}
      <div className="flex flex-col items-end px-6 w-full">
        <button
          className={`pt-5 text-xs text-blue-700 flex hover:text-teal-400 cursor-pointer z-[50] transition-transform ${
            minimized ? 'translate-x-9' : ''
          }`}
          onClick={() => setMinimized(!minimized)}
        >
          <div className="rounded-full bg-white p-1">
            <ChevronDoubleLeftIcon className={`w-4 transition-all ${minimized && 'rotate-180'}`} />
          </div>
        </button>
      </div>

      {/* Etana Digital Logo & Text */}
      <div className="align-top flex pb-5 pl-6 h-[100px] items-center">
        <div className="flex overflow-hidden cursor-pointer" onClick={() => navigate('/dashboard')}>
          <img
            className="h-50"
            src={logo_icon}
            alt="Etana Digital's logo: a colored square enclosed within two outer L-shapes, all coming together to make a larger square."
          />
          <img
            className={`${
              minimized ? 'opacity-0 -z-20 -translate-x-24' : 'translate-x-0 delay-75 duration-150'
            } ml-2 w-[120px] object-contain`}
            src={logo_text}
            alt="Etana Digital, with emphasis on 'Etana', as Etana digital is a subcustodian at Etana Custody."
          />
        </div>
      </div>

      {/* Timeouts */}
      <Timer seconds={sessionTime} action="sessionTimer" />
      <SessionTimeout props={{ minimized, setMinimized }} />

      {/* Navbar links */}
      <div className="flex flex-col items-center w-full">
        <div className="justify-start flex-col w-full items-start mt-8 px-2 z-[50]">
          {routes[role]?.map((item, i) => (
            <CustomLink
              key={i}
              minimized={minimized}
              name={item.name}
              icon={item.icon}
              to={item.path}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
