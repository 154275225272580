import { useDispatch } from 'react-redux';
import { hideModal } from '../../../store/modal';
import { XMarkIcon } from '@heroicons/react/24/outline';

const ManagerList = ({ managers, fn }) => {
  const dispatch = useDispatch();
  return (
    <div className="bg-black text-gray-300 text-sm p-10 w-96 max-h-[400px]">
      <div className="flex justify-between">
        <h2 className="text-xl font-bold mb-5">Managers</h2>
        <div name="close-icon">
        <XMarkIcon onClick={() => dispatch(hideModal())} className="w-6" />
        </div>
      </div>
      <ul className="max-h-[200px] overflow-y-auto">
        {managers?.map((mgr, i) => (
          <li key={i} className="flex items-center">
            <span>{mgr}</span>
            {fn && (
              <span>
                <XMarkIcon
                  onClick={() => fn(mgr)}
                  className="w-4 hover:text-red-500 cursor-pointer"
                />
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManagerList;
