import { useEffect, useState, FormEvent, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import validator from 'validator';

import { icon } from '../../../config/tailwind.classnames';
import axiosRequest from '../../../store/axios';
import { BuildingLibraryIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import { useUser } from '../../../hooks/useUser';

const admins = 'admins';
const managers = 'investors';

const NewUser = () => {
  // TODO: add user to UserContextInterface
  const  { user: { role, orgId } } = useUser();
  const [newUserEmail, setNewUserEmail] = useState<string>('');
  const [newUserDefaultGroup, setNewUserDefaultGroup] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [familyName, setFamilyName] = useState<string>('');
  const navigate = useNavigate();
  const isAdmin = role === 'admins';

  const { green, grey } = icon;

  useEffect(() => {
    if (!isAdmin) {
      toast.dismiss();
      toast.error('Unauthorized Access.');
      navigate('/dashboard');
    }
  }, [isAdmin, navigate, role]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    toast.dismiss();
    if (!name) return toast.error('Please enter a name.');
    if (!familyName) return toast.error('Please enter a family name.');
    // Error handling
    if (!newUserEmail || !validator.isEmail(newUserEmail)) {
      setNewUserEmail('');
      return toast.error('Please enter a valid email address.');
    }
    if (!newUserDefaultGroup) return toast.error('Please choose an access role.');

    const data = { givenName: name, familyName, email: newUserEmail, group: newUserDefaultGroup };

    try {
      await axiosRequest(`/admin/user/create`, { method: 'POST', data }).then(res => {
        toast.dismiss();
        res.status >= 300
          ? toast.error(`Failed to invite ${newUserEmail}\nStatus Code: ${res.status}`)
          : toast.success(`Invitation email sent to ${newUserEmail}`);
        navigate('/dashboard/organization');
      });
        
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'An unexpected error occurred';
      toast.error(`Failed to invite ${newUserEmail}\nStatus: ${errorMessage}`);  
    }
  };

  const goBack = () => navigate('/dashboard/organization');

  return (
    <div className="flex ml-5 mt-16">
      <form onSubmit={handleSubmit} className="w-full bg-gray-900 p-10 !pr-0 rounded mr-5">
        <div className="flex justify-between items-center mb-5">
          <div className="flex justify-between items-center text-3xl">
            Invite User to Organization
          </div>
        </div>

        {/*New user form*/}
        <div className="grid grid-cols-3 gap-y-8 pt-10 items-center pr-10">
          {/*Organization*/}
          <label className="text-sm text-gray-200 h-full flex items-center">Organization</label>
          <div className="col-span-2 flex h-full justify-end w-full items-center">
            <div className="text-gray-600">{orgId}</div>
          </div>
          {/*Name*/}
          <label className="text-sm text-gray-200 h-full flex items-center">Name</label>
          <div className="col-span-2 flex h-full justify-evenly w-full items-center">
            <input
              autoComplete="off"
              type="text"
              data-lpignore="true"
              placeholder="Name"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              className="text-right flex text-sm bg-transparent text-white p-3 h-10 border-b w-full"
            />
          </div>
          {/*Family Name*/}
          <label className="text-sm text-gray-200 h-full flex items-center">Family Name</label>
          <div className="col-span-2 flex h-full justify-evenly w-full items-center">
            <input
              autoComplete="off"
              type="text"
              data-lpignore="true"
              placeholder="Family Name"
              value={familyName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFamilyName(e.target.value)}
              className="text-right flex text-sm bg-transparent text-white p-3 h-10 border-b w-full"
            />
          </div>
          {/*Email address*/}
          <label className="text-sm text-gray-200 h-full flex items-center">Email Address</label>
          <div className="col-span-2 flex h-full justify-evenly w-full items-center">
            <input
              autoComplete="off"
              type="text"
              data-lpignore="true"
              placeholder="new.user@example.com"
              value={newUserEmail}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setNewUserEmail(e.target.value)}
              className="text-right flex text-sm bg-transparent text-white p-3 h-10 border-b w-full"
            />
          </div>
          {/*Cognito group*/}
          <label className="text-sm text-gray-200 h-full flex items-center">Access Role</label>
          <div className="col-span-2 flex justify-between">
            <button
              className={`relative p-3 rounded-lg w-full text-sm flex items-center justify-center m-0.5 min-w-[80px] ${
                newUserDefaultGroup === admins
                  ? 'bg-red-800 hover:bg-red-700'
                  : 'bg-gray-800 hover:bg-gray-700'
              }`}
              type="button"
              onClick={() => setNewUserDefaultGroup(admins)}
            >
              <div className="flex">
                <BuildingLibraryIcon className="w-6 pr-2" />
                <span>Admin</span>
              </div>
            </button>
            <button
              className={`relative p-3 rounded-lg w-full text-sm flex items-center justify-center m-0.5 min-w-[80px] ${
                newUserDefaultGroup === managers
                  ? 'bg-red-800 hover:bg-red-700'
                  : 'bg-gray-800 hover:bg-gray-700'
              }`}
              type="button"
              onClick={() => setNewUserDefaultGroup(managers)}
            >
              <div className="flex">
                <WrenchScrewdriverIcon className="w-6 pr-2" />
                <span>Manager</span>
              </div>
            </button>
          </div>
        </div>

        {/*Submit & cancel buttons*/}
        <div className="w-full mt-8 flex justify-evenly pr-10">
          <button
            className={`${green} mr-3 hover:bg-gradient-to-br hover:from-green-400 hover:to-green-500/75`}
            type="submit"
          >
            Submit
          </button>
          <button className={`${grey} !text-white ml-3`} onClick={goBack} type="button">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewUser;
