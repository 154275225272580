import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { useState } from 'react';
import { NetworkLogo } from '../../../assets/networks';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';

export default function WalletListItem({ data, category, query }) {
  const navigate = useNavigate();

  const accounts = data?.accounts ?? {};
  const assets = Object.keys(accounts).reduce((accum, network) => {
    const networkAssets = accounts[network]?.assets ?? [];
    accum.push(...networkAssets.map(asset => ({ asset, network })));
    return accum;
  }, []);

  if (!data || !category) {
    return null;
  }

  return (
    <tr
      onClick={() => navigate(`/dashboard/wallets/${data.walletId}`)}
      className="h-10 text-xs cursor-pointer leading-none text-white hover:bg-[#FFFFFF10] border-b border-t border-dotted border-gray-400"
    >
      <td className={`pr-2 md:pr-4 ${data.active ? 'text-white' : '!text-red-500'}`}>
        <Highlighter
          highlightClassName={'HighlightActive'}
          textToHighlight={data.walletId}
          searchWords={[query]}
          caseSensitive={false}
          autoEscape={true}
        />
      </td>
      <td className={`pr-2 md:pr-4 ${data.active ? 'text-white' : '!text-red-500'}`}>
        <Highlighter
          highlightClassName="HighlightActive"
          textToHighlight={data.owner}
          searchWords={[query]}
          caseSensitive={false}
          autoEscape={true}
        />
      </td>
      <td className="pr-2 md:pr-4">
        <div>
          {data.managers.slice(0, 2).map((email, i) => (
            <div key={i}>{email}</div>
          ))}
        </div>
        <ExtraManagers data={data} />
      </td>
      <td onClick={evt => evt.stopPropagation()}>
        <TokensList assets={assets} />
      </td>
    </tr>
  );
}

/**
 * @param {any[]} assets
 * @returns {JSX.Element}
 * @constructor
 */
function TokensList({ assets }) {
  const [show, setShow] = useState(false);
  const disableExpandButton = assets.length < 5;
  const displayedAssets = show ? assets : assets.slice(0, 5);

  return (
    <div className="flex space-x-2 py-1">
      <div className="grid grid-cols-5 place-items-end gap-1">
        {displayedAssets.map((asset, i) => (
          <div className="w-6" key={i}>
            <NetworkLogo
              network={asset.network}
              token={asset.asset}
              size="s"
            />
          </div>
        ))}
      </div>
      <div>
        <button
          className={`flex items-center space-x-1 text-xs p-1 bg-fuchsia-600 hover:bg-fuchsia-700 rounded-md max-h-fit ${disableExpandButton ? 'opacity-0' : ''}`}
          disabled={disableExpandButton}
          onClick={() => setShow(prev => !prev)}
        >
          {show ? <MinusIcon className="w-3" /> : <PlusIcon className="w-3" />}
          {show ? 'Less' : 'More'}
        </button>
      </div>
    </div>
  );
}

/**
 * @param {any} data
 * @returns {JSX.Element | null}
 * @constructor
 */
function ExtraManagers({ data }) {
  const defaultShowCount = 2;
  const [hover, setHover] = useState(false);
  const extraManagersExist = Boolean(data?.managers?.length > defaultShowCount);

  if (!extraManagersExist) {
    return null;
  }

  return (
    <div>
      <span
        className="cursor-pointer text-[#22c55e] underline"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        and {data.managers.length - defaultShowCount} more
      </span>
      <div
        className={`rounded absolute left-3/4 -translate-x-1/2 bg-white text-black p-5 ${hover && extraManagersExist ? '' : 'hidden'}`}
      >
        <ul>
          {data.managers.slice(defaultShowCount).map((email, i) => (
            <li key={i}>{email}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
