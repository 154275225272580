import { Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';
import { SET_USER, REMOVE_USER } from './constants';
import { hideModal } from './modal';
import { toast } from 'react-hot-toast';
const cookies = new Cookies();

// ACTION CREATORS
const setUser = (data, role) => ({ type: SET_USER, data, role });
const removeUser = () => ({ type: REMOVE_USER });

// THUNKS

export const storeUserSession = (userState, MFACode) => async dispatch => {
  let user;
  if (userState && MFACode) {
    user = await Auth.confirmSignIn(userState, MFACode, 'SOFTWARE_TOKEN_MFA');
  } else {
    user = await Auth.currentAuthenticatedUser();
  }

  const data = {};
  let role;

  if (user && user.signInUserSession) {
    const session = user.signInUserSession;

    if (session.refreshToken) {
      const { token: refreshToken } = session.refreshToken;
      Object.assign(data, { ...data, refreshToken });
    }
    if (session.idToken) {
      const { jwtToken, payload } = session.idToken;

      const {
        'custom:webhookUrl': webhookUrl,
        'custom:country': country,
        'custom:organization': organization,
        'cognito:groups': groups,
        email_verified,
        phone_number,
        family_name,
        sub,
        given_name,
        email,
        exp,
      } = payload;

      if (!groups || !organization) {
        await Auth.signOut();
        cookies.remove('jwt_token', { path: '/' });
        await dispatch(removeUser());
        await dispatch(hideModal());
        toast.dismiss();
        toast.error('User is missing group or organization. Contact an administrator.');
        return;
      }

      if (groups.includes('admins')) role = 'admins';
      if (groups.includes('superadmins')) role = 'superadmins';
      else if (groups.includes('investors')) role = 'investors';

      Object.assign(data, {
        jwtToken,
        country,
        organization,
        groups,
        sub,
        email,
        email_verified,
        webhookUrl,
        given_name,
        family_name,
        phone_number,
        exp,
      });
    }
  }
  const { jwtToken } = user?.signInUserSession?.accessToken;
  // maxAge in seconds - set cookie expiration to 1 hour
  cookies.set('jwt_token', jwtToken, { path: '/', maxAge: 3600 });
  await dispatch(setUser(data, role));
  return jwtToken;
};

export const removeUserSession = () => async dispatch => {
  await Auth.signOut();
  cookies.remove('jwt_token', { path: '/' });
  await dispatch(removeUser());
};

// REDUCER
const sessionReducer = (state = { user: null, role: null }, action) => {
  const newState = { ...state };
  switch (action.type) {
    case SET_USER:
      newState.user = action.data;
      newState.role = action.role;
      return newState;
    case REMOVE_USER:
      newState.user = null;
      newState.role = null;
      return newState;
    default:
      return state;
  }
};

export default sessionReducer;
