import { useEffect, useState, FormEvent, ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { icon } from '../../../config/tailwind.classnames';
import axiosRequest from '../../../store/axios';
import { useUser } from '../../../hooks/useUser';

const admins = 'admins';

const DeleteUser = () => {
  const [confirmDeletionField, setConfirmDeletionField] = useState<string>('');
  const  { user: { role, orgId } } = useUser();
  const navigate = useNavigate();
  const { email } = useParams();
  const isAdmin = role === admins;

  const { green, grey } = icon;

  useEffect(() => {
    if (!isAdmin) {
      toast.dismiss();
      toast.error('Unauthorized Access.');
      navigate('/dashboard');
    }
  }, [isAdmin, navigate, role]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    toast.dismiss();

    // Error handling
    if (confirmDeletionField !== email) {
      setConfirmDeletionField('');
      return toast.error('Entered email address does not match');
    }

    const data = { email };
    axiosRequest(`/admin/user/delete`, { method: 'POST', data }).then(res => {
      toast.dismiss();
      res.status >= 300
        ? toast.error(`Failed to delete user ${email}\nStatus Code: ${res.status}`)
        : toast.success(`Deleted user`);
      navigate('/dashboard/organization');
      window.location.reload();
    });
  };

  return (
    <div className="flex ml-5 mt-16">
      <form onSubmit={handleSubmit} className="w-full bg-gray-900 p-10 !pr-0 rounded mr-5">
        <div className="flex justify-between items-center mb-5">
          <div className="flex justify-between items-center text-3xl">
            Delete User from Organization?
          </div>
        </div>

        <p className="text-center">
          {`WARNING: You are about to delete the user ${email} from your organization, ${orgId}.`}
        </p>
        <p className="text-red-700 text-center py-3">This action cannot be undone!</p>
        <p className="text-center">
          Please enter <span className="italic">{`${email}`}</span> in the field below to delete
          them from your organization.
        </p>
        {/*form*/}
        <div className="grid grid-cols-3 gap-y-8 pt-10 items-center pr-10">
          {/*Organization*/}
          <label className="text-sm text-gray-200 h-full flex items-center">Organization</label>
          <div className="col-span-2 flex h-full justify-end w-full items-center">
            <div className="text-gray-600">{orgId}</div>
          </div>
          {/*Email address*/}
          <label className="text-sm text-gray-200 h-full flex items-center">Email Address</label>
          <div className="col-span-2 flex h-full justify-evenly w-full items-center">
            <input
              autoComplete="off"
              type="text"
              data-lpignore="true"
              placeholder={email}
              value={confirmDeletionField}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setConfirmDeletionField(e.target.value)
              }
              className="text-right flex text-sm bg-transparent text-white p-3 h-10 border-b w-full"
            />
          </div>
        </div>

        {/*Submit & cancel buttons*/}
        <div className="w-full mt-8 flex justify-evenly pr-10">
          <button
            className={`${green} disabled:opacity-50 mr-3 hover:bg-gradient-to-br hover:from-green-400 hover:to-green-500/75`}
            disabled={confirmDeletionField !== email}
            type="submit"
          >
            Submit
          </button>
          <button
            className={`${grey} !text-white ml-3`}
            onClick={() => navigate('/dashboard/organization')}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default DeleteUser;
