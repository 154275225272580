import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';

const getSessionExpiration = async () => {
  try {
    const session = await Auth.currentSession();

    const jwt = (session as any)?.accessToken?.jwtToken;
    const decodedJWT = jwt_decode<any>(jwt);
    const expiration = decodedJWT?.exp;

    // JavaScript Date constructor expects time in milliseconds (exp in seconds)
    const expTime = new Date(expiration * 1000).getTime();
    const currentTime = new Date().getTime();

    // const differenceInMinutes = (expTime - currentTime) / (1000 * 60);
    // const secondsOnly = (differenceInMinutes % 1) * 60;

    // time in seconds until expiration
    return (expTime - currentTime) / 1000;
  } catch (e) {
    console.log(e);
  }
};

export default getSessionExpiration;
