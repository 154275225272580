import { useSelector } from 'react-redux';
import { ArrowDownOnSquareStackIcon } from '@heroicons/react/24/outline';
import csvDownload from 'json-to-csv-export';
import { JSX } from 'react';

export type CSVDownloadParams = { title: string; documents: Record<string, string> };

export function CSVDownload({ title, documents }: CSVDownloadParams): JSX.Element {
  const user = useSelector((state: any) => state.session.user);
  function download() {
    // Convert object of objects into array of objects
    const data = Object.values(documents);
    // set the file name as ORGANIZATION_transactions_currentDate
    const filename = `${user?.organization}_${title}_${new Date().toISOString()}`;
    // get the headers from the keys existing in the first object in the array
    const headers = Object.keys(data[0]);
    const delimiter = ',';
    csvDownload({ data, filename, delimiter, headers });
  }

  if (!documents || Object.values(documents)?.length === 0) {
    return null;
  }
  return (
    <button
      className="flex bg-gray-700 rounded px-2 py-1 hover:bg-gray-600 text-xs items-center text-center"
      onClick={download}
    >
      <ArrowDownOnSquareStackIcon className="w-6 pr-1" />
      CSV ({title})
    </button>
  );
}

export default CSVDownload;
