import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { hideModal, currentComponent } from '../store/modal';
import refreshSessionToken from './tokenRefresh';
import jwt_decode from 'jwt-decode';

const Timer = ({ seconds, action }) => {
  const [counter, setCounter] = useState(seconds);
  const [useInterval, setUseInterval] = useState(0);
  const timerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (counter > 0) timerRef.current = setInterval(() => setCounter(counter - 1), 1000);
    if (action === 'sessionTimer') {
      // Refresh token at 5 minutes
      if (counter < 300) {
        refreshSessionToken();
        setCounter(3600);
      }

      // Synchronize the setInterval with the JWT expiration every 60 seconds
      setUseInterval(useInterval + 1);
      if (useInterval % 60 === 0) {
        Auth.currentSession().then(session => {
          const jwt = session.getAccessToken().getJwtToken();
          const decoded = jwt_decode(jwt);
          const expTime = new Date(decoded?.exp * 1000).getTime();
          const currentTime = new Date().getTime();
          setCounter((expTime - currentTime) / 1000);
        });
      }
    } else {
      if (counter <= 0) {
        toast('Session timeout. Please login again.');
        dispatch(hideModal());
        dispatch(currentComponent(null));
      }
    }

    return () => clearInterval(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <div className={`${(counter > 300 || action === 'sessionTimer') && 'hidden'} flex`}>
      {action === 'sessionTimer' && <span className="mr-2">Session timeout in: </span>}
      <div
        className={`${
          counter < 30 ? 'text-red-500 animate-pulse' : 'text-white'
        } flex justify-center`}
      >
        <span id="minutes">{Math.max(Math.floor(counter / 60), 0)}:</span>
        {counter % 60 < 10 ? (
          <span>{`0${Math.max(Math.floor(counter % 60), 0)}`}</span>
        ) : (
          <span>{Math.max(Math.floor(counter % 60), 0)}</span>
        )}
      </div>
    </div>
  );
};

export default Timer;
