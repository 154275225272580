import { useState, useEffect, useRef } from 'react';
import { SingleLeftWithDecrement, SingleRightWithIncrement } from '../../../assets/icons/arrows';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  TrashIcon,
  PencilIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import useSort from '../../../hooks/useSort';
import Highlighter from 'react-highlight-words';
import usePaginate from '../../../hooks/usePaginate';
import capitalize from '../../../utils/capitalize';
import { useNavigate } from 'react-router-dom';
import Field, { setField } from './Field';
import { toast } from 'react-hot-toast';
import { User, UserContextInterface } from '../../../types/interfaces';
import { AxiosResponse } from 'axios';
import { UsersServices } from '../../../services/users.services';
import { useUser } from '../../../hooks/useUser';

type ActiveField = User & { index: number };

type UserTableProps = {
  group: string;
  query: string;
  users: User[];
  userRef: UserContextInterface['userRef'];
};

type UserTableContentProps = UserTableProps & { expand: boolean };

const UserTables = ({ group, query, users, userRef }: UserTableProps) => {
  const [expand, setExpand] = useState(true);

  return (
    <div className="md:min-w-[500px] lg:min-w-[700px]">
      <h2 className="p-3 rounded-t flex justify-between items-center bg-gradient-to-b from-gray-600 via-gray-700 to-gray-800">
        <div
          className="flex hover:text-green-400 cursor-pointer"
          onClick={() => setExpand(current => !current)}
        >
          <span className="pr-2">
            {expand ? <ChevronDownIcon className="w-6" /> : <ChevronRightIcon className="w-6" />}
          </span>
          <span>{capitalize(group)}</span>
        </div>
      </h2>
      <UserTableContent
        expand={expand}
        users={users}
        query={query}
        group={group}
        userRef={userRef}
      />
    </div>
  );
};

function UserTableContent({ expand, users, query, group, userRef }: UserTableContentProps) {
  const navigate = useNavigate();
  const {
    user: { groups },
  } = useUser();
  const [activeSort, setActiveSort] = useState(null);
  const [activeEdit, setActiveEdit] = useState<ActiveField>();
  const { sortedItems, requestSort } = useSort(users);
  const { prev, next, page, set } = usePaginate(sortedItems, 10);

  const containerRef = useRef<HTMLDivElement>(null);

  function sortRequest(key: string) {
    const dir = requestSort(key);
    setActiveSort(`${key}--${dir}`);
  }

  const handleEditClick = (user: User, index: number) => {
    if (activeEdit?.index === index) {
      setActiveEdit(null);
    } else {
      setActiveEdit({ index, ...user });
    }
  };

  const handleSave = () => {
    const onStart = () => {
      toast.dismiss();
    };
    const onSuccess = () => {
      setActiveEdit(null);
      toast.success('User updated');
      set(Object.assign(users[activeEdit?.index as number], {
        givenName: activeEdit?.givenName,
        familyName: activeEdit?.familyName,
      }), activeEdit?.index as number);
    };
    const onError = (res: AxiosResponse) => {
      toast.error(`Failed to update user\nStatus Code: ${res.status}`);
    };
    const data = {
      givenName: activeEdit?.givenName,
      familyName: activeEdit?.familyName,
    };
    UsersServices.updateUser({
      userId: activeEdit?.sub,
      data,
      onStart,
      onSuccess,
      onError,
      userRef,
    });
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setActiveEdit(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!expand) {
    return null;
  }

  return (
    <div ref={containerRef} className="rounded-b text-sm bg-gray-900">
      <div className="px-4">
        <div className="text-center py-5 text-gray-400" hidden={page.data().length > 0}>
          No {group}
        </div>
        <table className="w-full whitespace-nowrap" hidden={page.data().length === 0}>
          <thead>
            <tr className="text-left text-gray-400 text-xs font-semibold">
              <th
                className="flex items-center hover:text-green-400 cursor-pointer h-full"
                onClick={() => sortRequest('email')}
              >
                <span className="pr-1">Email</span>
                <span
                  className={activeSort?.split('--')[0] === 'email' ? 'opacity-1' : 'opacity-0'}
                >
                  {activeSort?.split('--')[1] === 'ascending' ? (
                    <ChevronUpIcon className="w-3" />
                  ) : (
                    <ChevronDownIcon className="w-3" />
                  )}
                </span>
              </th>
              <th>First Name</th>
              <th>Last Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {page.data().map((user: User, index: number) => (
              <tr className="border-y border-dotted py-1 border-gray-500" key={user.email}>
                <td>
                  <Highlighter
                    highlightClassName="HighlightActive"
                    textToHighlight={user.email}
                    searchWords={[query]}
                    caseSensitive={false}
                    autoEscape={true}
                  />
                </td>
                <td>
                  {user.givenName ? (
                    <Field
                      value={activeEdit?.index === index ? activeEdit.givenName : user.givenName}
                      searchWords={query}
                      editActive={activeEdit?.index === index}
                      set={value =>
                        setField<ActiveField>({
                          fieldName: 'givenName',
                          value,
                          setActiveEdit,
                        })
                      }
                    />
                  ) : (
                    <span className="opacity-50">Unavailable</span>
                  )}
                </td>
                <td>
                  {user.familyName ? (
                    <Field
                      value={activeEdit?.index === index ? activeEdit.familyName : user.familyName}
                      searchWords={query}
                      editActive={activeEdit?.index === index}
                      set={value =>
                        setField<ActiveField>({
                          fieldName: 'familyName',
                          value,
                          setActiveEdit,
                        })
                      }
                    />
                  ) : (
                    <span className="opacity-50">Unavailable</span>
                  )}
                </td>
                <td>
                  <span hidden={!groups.includes('admins') && !groups.includes('superadmins')}>
                    <button
                      onClick={() => handleEditClick(user, index)}
                      className="text-gray-300 hover:text-opacity-80"
                      hidden={activeEdit?.index === index}
                      disabled={activeEdit?.index === index}
                    >
                      {activeEdit?.index === index ? (
                        <CheckIcon className="w-4" />
                      ) : (
                        <PencilIcon className="w-4" />
                      )}
                    </button>
                    <button
                      onClick={handleSave}
                      hidden={activeEdit?.index !== index}
                      className="text-green-400 hover:opacity-80"
                    >
                      <CheckIcon className="w-4" />
                    </button>
                    <button
                      onClick={() => navigate(`delete/${user.email}`)}
                      className="text-red-600 hover:opacity-80 pl-3"
                      disabled={!!activeEdit}
                    >
                      <TrashIcon className="w-4" />
                    </button>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <span className="flex justify-between py-4 text-gray-300 text-xs">
          <SingleLeftWithDecrement decrement={page.current === 1 ? null : prev} />
          <span>
            {page.from} - {page.to} of {sortedItems.length} {group}
          </span>
          <SingleRightWithIncrement increment={page.current === page.max ? null : next} />
        </span>
      </div>
    </div>
  );
}

export default UserTables;
