import {
  useEffect,
  useState,
  useRef,
  useMemo,
  ChangeEvent,
  ChangeEventHandler,
  ReactNode,
} from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import useClickOutside from '../../../../hooks/useClickOutside';
import { getWalletAssets } from '../../../../store/wallets';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { getAssetsList } from '../../../../utils/assets';
import Select from 'react-select';
import { base } from '../../../../config/select.styles';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';

type Filter = {
  assets: Record<string, boolean | null>;
  approvalStatus: { [key: string]: boolean };
  seenAt: string | null;
  type: { [key: string]: boolean };
};

type BaseTransaction = {
  asset: string;
  network: string;
  seenAt: string;
  dateCreated: string;
  walletId: string;
  originAddress: string;
  originAddresses: string[];
  txId: string;
};

type SearchCategory = {
  label: string;
  key: string;
};

export type FilterEntriesProps = {
  filter: Filter;
  setFilter: (filter: any | ((curr: any) => void)) => void;
  jump: (page: number) => void;
  items: BaseTransaction[];
  setFilteredItems: (items: BaseTransaction[]) => void;
  searchCategory: SearchCategory;
  searchQuery: string;
  walletId?: string;
  organizationVisibility: any;
  customFilterByItem?: (item: BaseTransaction) => boolean;
  CustomFilterComponent?: ReactNode;
  itemDateField: keyof BaseTransaction;
};

export type SearchToolbarProps = {
  setSearchQuery: (query: string) => void;
  setActiveCategory: (searchCategory: SearchCategory) => void;
  activeCategory: SearchCategory;
  searchQuery: string;
  allCategoryOptions: SearchCategory[];
};

export const SearchToolbar = ({
  searchQuery,
  setSearchQuery,
  activeCategory,
  setActiveCategory,
  allCategoryOptions,
}: SearchToolbarProps) => {
  const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = (e: OnChangeValue<any, any>) => {
    setSearchQuery('');
    setActiveCategory(e);
  };
  return (
    <div className="flex py-3">
      <span className="min-w-[80px] flex items-center">Search by</span>
      <div className="pr-3">
        <Select
          className="w-36 hover:bg-gray-600 ml-4"
          defaultValue={{ label: 'Wallet ID', key: 'walletId' }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          options={[{ label: 'Wallet ID', key: 'walletId' }, ...allCategoryOptions]}
          placeholder="select prefix"
          onChange={handleSelect}
          styles={base}
        />
      </div>
      <input
        className="w-full bg-transparent border-b p-1 focus:outline-none"
        placeholder={activeCategory.label}
        onChange={handleSearchInputChange}
        value={searchQuery}
      />
    </div>
  );
};

export const FilterEntries = ({
  filter,
  setFilter,
  items,
  setFilteredItems,
  jump,
  organizationVisibility,
  searchCategory,
  customFilterByItem,
  itemDateField,
  CustomFilterComponent,
  walletId,
  searchQuery,
}: FilterEntriesProps) => {
  const dispatch = useDispatch();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isFilterEmpty, setIsFilterEmpty] = useState(false);
  const filterWrapperRef = useRef<HTMLDivElement>(null);
  const { role } = useSelector((state: any) => state.session);
  const wallets = useSelector((state: any) => state.wallets);

  const walletAssets = useSelector((state: { wallets: { assets: any[] } }) => state.wallets.assets);

  const dateOptions = [
    { value: '24h', label: 'Last 24 hours' },
    { value: '7d', label: 'Last 7 days' },
    { value: '30d', label: 'Last 30 days' },
    { value: 'all', label: 'All Time' },
  ];

  const showNothing = useMemo(
    () => ({
      assets: {},
      approvalStatus: {},
      type: {},
      seenAt: 'all',
    }),
    [],
  );

  useEffect(() => {
    setIsFilterEmpty(() => {
      const { seenAt } = filter;
      const filterKeys = Object.keys(filter).filter(
        key => typeof filter[key as keyof Filter] === 'object',
      ) as (keyof Filter)[];
      return (
        filterKeys.every(filterKey => Object.values(filter[filterKey] || {}).every(v => !v)) &&
        seenAt === 'all'
      );
    });
    jump(1);
  }, [filter, jump]);

  useEffect(() => {
    setFilter((prev: any) => ({
      ...prev,
      ...showNothing,
    }));

    dispatch(getWalletAssets() as unknown as AnyAction).catch((e: any) => {
      console.log(e);
      toast.error(`Error getting wallet assets: ${e}`);
    });
  }, [dispatch, setFilter, showNothing]);

  useEffect(() => {
    const filteredData = Object.values(items)?.filter(item => {
      if (role === 'superadmins' && wallets) {
        const wallet = wallets[item.walletId];
        if (!wallet || !organizationVisibility[wallet.orgId]) return false;
      }
      if (walletId || searchCategory.key === 'walletId') {
        if (!item.walletId.includes(walletId || searchQuery)) {
          return false;
        }
      } else if (searchCategory.key === 'origin') {
        const originString =
          item.originAddress ||
          (item.originAddresses && item.originAddresses.length > 1
            ? `${item.originAddresses[0]} and ${item.originAddresses.length - 1} more`
            : item.originAddresses[0]);
        if (!originString || !originString.includes(searchQuery)) {
          return false;
        }
      } else if (searchCategory.key === 'txId' && !item.txId.includes(searchQuery)) {
        return false;
      }
      if (customFilterByItem && !customFilterByItem(item)) {
        return false;
      }
      const currentDate = new Date().getTime();
      const itemDate = new Date(item[itemDateField] as string).getTime();
      let isDateInRange = false;
      switch (filter.seenAt) {
        case '24h':
          isDateInRange = currentDate - itemDate <= 24 * 60 * 60 * 1000;
          break;
        case '7d':
          isDateInRange = currentDate - itemDate <= 7 * 24 * 60 * 60 * 1000;
          break;
        case '30d':
          isDateInRange = currentDate - itemDate <= 30 * 24 * 60 * 60 * 1000;
          break;
        case 'all':
        default:
          isDateInRange = true;
          break;
      }
      if (!isDateInRange) {
        return false;
      }

      const filterKey = `${item.network}-${item.asset}`;
      const isFilteringNetworks = Object.values(filter.assets).some(v => v);
      if (
        Object.values(filter.assets).every(v => !v) ||
        (filter.assets[filterKey] && isFilteringNetworks)
      ) {
        return true;
      } else if (isFilteringNetworks && !filter.assets[filterKey]) {
        return false;
      }

      return true;
    });
    setFilteredItems(filteredData);
  }, [
    items,
    wallets,
    organizationVisibility,
    role,
    searchQuery,
    searchCategory,
    filter,
    setFilteredItems,
    walletId,
    itemDateField,
    // customFilterByItem, // TODO: this useEffect depends on customFilterByItem, but this creates a render loop
  ]);

  const clearAll = () => {
    if (isFilterEmpty) return;
    const clearedFilter = {
      ...filter,
      assets: Object.keys(filter.assets).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as typeof filter.assets),
      approvalStatus: Object.keys(filter.approvalStatus).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as typeof filter.approvalStatus),
      type: Object.keys(filter.type || {}).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as typeof filter.type),
      seenAt: 'all',
    };
    setFilter(clearedFilter);
    setIsFilterEmpty(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFilter((prev: any) => ({
      ...prev,
      assets: { ...prev.assets, [name]: checked },
    }));
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter((prev: any) => ({
      ...prev,
      seenAt: e.target.value,
    }));
  };

  const handleOpenMenu = () => setFilterMenuOpen(true);
  const handleCloseMenu = () => setFilterMenuOpen(false);

  useClickOutside(filterWrapperRef, handleCloseMenu);

  return (
    <div className="relative self-center text-white group h-fit" ref={filterWrapperRef}>
      <button
        onClick={filterMenuOpen ? handleCloseMenu : handleOpenMenu}
        className={`${
          filterMenuOpen ? 'bg-gray-700 rounded-t' : 'rounded'
        } flex hover:bg-gray-600 items-center text-sm space-x-2 p-2`}
      >
        <FunnelIcon className="w-4" />
        <span>Filter</span>
      </button>
      {filterMenuOpen && (
        <div className="absolute right-0 z-30 p-5 transition-opacity duration-500 rounded-b rounded-l shadow-2xl group bg-slate-700 min-w-[400px] md:min-w-[600px] space-y-6">
          <div className="flex flex-col space-y-1">
            <div className="text-sm font-bold text-left">Assets</div>
            <div className="grid grid-cols-2">
              {getAssetsList(walletAssets).map(asset => (
                <label className="flex text-white justify-start text-sm space-x-4" key={asset.id}>
                  <input
                    type="checkbox"
                    name={asset.id}
                    onChange={handleChange}
                    checked={filter.assets[asset.id]}
                  />
                  <span>{asset.asset.toUpperCase()}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-1">
            <div className="text-sm font-bold text-left">Date Seen</div>
            <div className="flex flex-wrap space-x-4">
              {dateOptions.map(option => (
                <label className="flex items-center text-sm space-x-2" key={option.value}>
                  <input
                    type="radio"
                    name="dateSeen"
                    value={option.value}
                    onChange={handleDateChange}
                    checked={filter.seenAt === option.value}
                  />
                  <span>{option.label}</span>
                </label>
              ))}
            </div>
          </div>

          {CustomFilterComponent}

          <div className="flex justify-end text-sm">
            <div
              className={
                !isFilterEmpty
                  ? 'text-white cursor-pointer hover:text-green-600'
                  : 'text-gray-500 cursor-not-allowed select-none'
              }
              onClick={clearAll}
            >
              Clear Filters
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterEntries;
