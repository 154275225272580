import { useState, useMemo } from 'react';

const useSort = (list: any[]) => {
  const [sortConfig, setSortConfig] = useState(null);

  const requestSort = (key: string) => {
    let direction = 'ascending';
    if (sortConfig?.key === key && sortConfig?.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    return direction;
  };

  const sortedItems = useMemo(() => {
    if (list !== undefined) {
      let sortableItems = [...list];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }
  }, [list, sortConfig]);
  return { sortedItems, requestSort, sortConfig };
};

export default useSort;
