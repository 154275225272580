import { ChangeEvent, Dispatch, FormEvent, SetStateAction } from 'react';
import Highlighter from 'react-highlight-words';

interface Props {
  value: string;
  labelText?: string;
  set: (e: string) => void;
  editActive: boolean;
  searchWords: string;
}

export const setField = <T,>({
  fieldName,
  value,
  setActiveEdit,
}: {
  fieldName: keyof T;
  value: string;
  setActiveEdit: Dispatch<SetStateAction<T>>;
}) =>
  setActiveEdit((activeEdit: T) => ({
    ...activeEdit,
    [fieldName]: value,
  }));

const Field = ({ editActive, value, set, labelText, searchWords }: Props) => {
  return (
    <form onSubmit={(e: FormEvent) => e.preventDefault()}>
      <label hidden={!labelText}>{labelText}:</label>
      {editActive ? (
        <input
          className="bg-transparent border-b focus:outline-none p-1"
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => set(e.target.value)}
        />
      ) : (
        <Highlighter
          highlightClassName="HighlightActive"
          textToHighlight={value}
          searchWords={[searchWords]}
          caseSensitive={false}
          autoEscape={true}
        />
      )}
    </form>
  );
};

export default Field;
