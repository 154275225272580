import { baseCard } from '../../../config/tailwind.classnames';

export default function StakingList() {
  return (
    <div className="p-10">
      <div className="flex items-center justify-between pb-5">
        <p className="text-2xl text-gray-100">Staking</p>
        <p></p>
      </div>
      <div className={baseCard}>Under Construction</div>
    </div>
  );
}
