import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { currentComponent, hideModal } from '../../store/modal';
import { QRCodeSVG } from 'qrcode.react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import Timer from '../../utils/Timer';

import { storeUserSession } from '../../store/session';

const MFA = ({ userState, username }) => {
  const [qrCodeValue, setQRCodeValue] = useState('');
  const [mfaCode, setMFACode] = useState('');

  const [needHelp, setNeedHelp] = useState(false);
  const [expandQR, setExpandQR] = useState(false);
  const challengeName = userState?.challengeName;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setMFACode('');
    if (challengeName === 'MFA_SETUP') {
      Auth.setupTOTP(userState)
        .then(s => setQRCodeValue(`otpauth://totp/EtanaDigital:%20${username}?secret=${s}&issuer=`))
        .catch(err => console.log(`Error setting up MFA: ${err}`));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitMFASetup = async e => {
    // First time setup of Multi-Factor Authentication
    e.preventDefault();
    if (!mfaCode) return;

    try {
      toast('Submitting code for verification...');
      await Auth.verifyTotpToken(userState, mfaCode);
      await Auth.setPreferredMFA(userState, 'SOFTWARE_TOKEN_MFA');

      toast.dismiss();
      toast.success('Thank you for setting up MFA. Please login again.');
      dispatch(hideModal());
      dispatch(currentComponent(null));
    } catch (e) {
      toast.dismiss();
      toast.error(e.message);
      console.error(`Error submitting MFA Code: ${e}`);
    }
  };

  const submitMFA = e => {
    e.preventDefault();
    if (!mfaCode) {
      toast.error('Please enter your MFA code.');
      return;
    }
    toast('Submitting code for verification...');
    dispatch(storeUserSession(userState, mfaCode))
      .then(res => {
        if (res) {
          toast.dismiss();
          navigate('/authenticating');

          dispatch(hideModal());
          dispatch(currentComponent(null));
        }
      })
      .catch(err => {
        toast.dismiss();
        toast.error('Incorrect MFA code.');
        console.error(`Error submitting MFA Code: ${JSON.stringify(err)}`);
      });
  };

  if (challengeName === 'MFA_SETUP') {
    return (
      <>
        <div className="absolute flex flex-col w-full justify-center text-white top-0 bottom-0 left-0 right-0 p-16">
          <div className="bg-black bg-opacity-80 rounded-lg w-full h-full flex flex-col justify-evenly items-center overflow-hidden relative">
            <div className="items-center relative justify-center flex opacity-80 text-sm mt-2 p-3 rounded border-2 border-white w-[60%] shadow-innerWhite overflow-hidden">
              <span className="mr-2 text-xs">session ends in:</span>
              <Timer seconds={180} />
            </div>
            <div className="text-2xl my-3">MFA Setup</div>
            <div className="mb-5">
              Please scan QR Code with an Authenticator App (e.g. Google Authenticator)
            </div>
            <div
              className="mb-10 text-sm text-yellow-300 cursor-pointer"
              onClick={() => setNeedHelp(true)}
            >
              Need Help?
            </div>
            <div className="bg-white p-2">
              <QRCodeSVG
                value={qrCodeValue}
                className="cursor-pointer"
                onClick={() => setExpandQR(true)}
              />
            </div>
            <div className="text-xs text-gray-500 mt-3">Click QR Code to Expand</div>

            <form
              className="flex flex-col items-center justify-center w-full p-10"
              onSubmit={submitMFASetup}
            >
              <label>Please enter the 6-digit code from the Authenticator app</label>
              <input
                type="text"
                value={mfaCode}
                placeholder="######"
                ref={input => input?.focus()}
                className="p-5 h-16 w-72 bg-gray-800 text-white rounded text-center text-xl tracking-[1em] pl-9 mt-8 mb-8"
                onChange={e => setMFACode(e.target.value)}
              />

              <button type="submit" className="w-64 bg-gray-600 text-white p-5 rounded">
                Setup MFA
              </button>
            </form>
          </div>
          {expandQR && (
            <div
              className="absolute self-center bg-white p-10 cursor-pointer rounded-lg"
              onClick={() => setExpandQR(false)}
            >
              <QRCodeSVG size="350" value={qrCodeValue} className="cursor-pointer" />
              <div className="mt-8 text-gray-400 text-center">Click to Close</div>
            </div>
          )}
          {needHelp && (
            <div
              className="absolute z-[90] self-center bg-black text-white bg-opacity-90 p-5 pl-16 pr-16 cursor-pointer rounded-lg border border-white shadow-offsetWhite"
              onClick={() => setNeedHelp(false)}
            >
              <h2 className="text-2xl text-center">Instructions</h2>
              <ul className="list-disc">
                <li>
                  Download Google Authenticator from the App Store or Google Play Store on your
                  phone
                </li>
                <li>Open up the app</li>
                <li>Select the "+" symbol in the lower right-hand corner of the screen</li>
                <li>Select "Scan a QR code"</li>
                <li>Center the green square on the QR code symbol</li>
                <li>Enter the generated six-digit code</li>
              </ul>
              <div className="mt-5 text-gray-600 flex justify-end">click to close</div>
            </div>
          )}
        </div>
      </>
    );
  }

  if (challengeName === 'SOFTWARE_TOKEN_MFA') {
    return (
      <div className="absolute flex flex-col w-full justify-center text-white top-0 bottom-0 left-0 right-0 p-16">
        <div className="bg-black bg-opacity-80 rounded-lg w-full h-full flex flex-col justify-evenly items-center overflow-hidden">
          <div className="items-center relative justify-center flex opacity-80 text-sm mt-2 p-3 rounded border-2 border-white w-[60%] shadow-innerWhite overflow-hidden">
            <span className="mr-2">session ends in:</span>
            <Timer seconds={120} />
          </div>
          <div>
            <div className="text-xl text-center">
              Enter the 6-digit code from your Authenticator app
            </div>
            <div className="text-xs text-center text-yellow-400 mt-2">
              First Time Users: Please do not use the same code used for MFA setup.
            </div>
          </div>
          <form
            className="flex flex-col items-center justify-center w-full p-10"
            onSubmit={submitMFA}
          >
            <input
              className="p-5 w-full max-w-[350px] h-16 mb-10 bg-gray-800 text-white rounded text-center text-xl tracking-[1em] pl-10"
              type="text"
              value={mfaCode}
              placeholder="######"
              maxLength={6}
              ref={input => input?.focus()}
              onChange={e => setMFACode(e.target.value)}
            />
            <button
              type="submit"
              className="w-full max-w-[350px] bg-gray-600 text-white p-5 rounded mt-8 hover:bg-green-600 overflow-hidden"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }

  return <div>{console.debug(`DEBUG: Unhandled MFA condition: ${challengeName}`)}</div>;
};

export default MFA;
