import { toast } from 'react-hot-toast';
import {
  ClipboardDocumentIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
  LinkIcon,
  RocketLaunchIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';

type CopyToClipboardInput = { label: string; text: string; className?: string };

/**
 * @description - A clipboard icon that enables copying text to the clipboard.
 * @constructor
 */
export const CopyToClipboard = ({ label, text, className }: CopyToClipboardInput) => {
  return (
    <div>
      <span
        className="hover:text-blue-300 cursor-pointer"
        onClick={() => {
          navigator.clipboard
            .writeText(text)
            .then(() => {
              toast.success(`${label} copied to clipboard.`);
            })
            .catch(err => {
              toast.error('Failed to copy to keyboard');
            });
        }}
      >
        <ClipboardDocumentIcon className={className || 'w-4'} />
      </span>
    </div>
  );
};

export const TxStatus = ({ status }: { status: string }) => {
  switch (status) {
    case 'success': {
      return (
        <td className="bg-gradient-to-br from-emerald-400 via-green-400 to-green-700 w-4 border-x border-white">
          <div className="flex justify-center items-center">
            <LinkIcon className="w-4" />
          </div>
        </td>
      );
    }
    case 'failure': {
      return (
        <td className="bg-gradient-to-br from-red-500/25 via-red-600/75 to-red-800/50 w-4 border-x border-white">
          <div className="flex justify-center items-center">
            <XCircleIcon className="w-4" />
          </div>
        </td>
      );
    }
    case 'cancelled': {
      return (
        <td className="bg-gradient-to-br from-black to-gray-800/75 w-4 border-x border-white opacity-80">
          <div className="flex justify-center items-center">
            <ExclamationCircleIcon className="w-4" />
          </div>
        </td>
      );
    }
    case 'submitted': {
      return (
        <td className="bg-gradient-to-br from-blue-400 via-blue-400 to-blue-700 w-4 border-x border-white">
          <div className="flex justify-center items-center">
            <RocketLaunchIcon className="w-4" />
          </div>
        </td>
      );
    }
    default:
      return (
        <td className="bg-gradient-to-br from-gray-500/25 via-gray-600/75 to-gray-800/50 w-4 border-x border-white opacity-30">
          <div className="flex justify-center items-center">
            <EllipsisHorizontalCircleIcon className="w-4" />
          </div>
        </td>
      );
  }
};
