type Styles = {
  background: string;
  border: string;
  borderRadius: string;
  color: string;
  marginBottom: string;
  marginTop: string;
  paddingBottom: string;
  paddingTop: string;
};

export const auth = {
  control: (styles: Styles) => ({
    ...styles,
    border: 'none',
    borderRadius: '0px',
    color: 'white',
    background: 'rgba(50, 50, 50, 0)',
  }),
  option: (styles: Styles, { isFocused }: { isFocused: boolean }) => {
    return {
      ...styles,
      background: isFocused ? '#4282B0' : 'none',
      border: 'none',
      borderRadius: '0px',
      color: 'white',
      marginBottom: '0px',
      marginTop: '0px',
      paddingBottom: '5px',
      paddingTop: '5px',
    };
  },
  menu: (base: Styles) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
    background: 'rgba(14, 14, 14, 0.8)',
    color: 'white',
    borderRadius: '0px',
    paddingBottom: '0px',
    paddingTop: '0px',
  }),
  singleValue: (base: Styles) => ({ ...base, color: 'white' }),
  input: (base: Styles) => ({ ...base, color: 'white' }),
  placeholder: (base: Styles) => ({ ...base, color: 'white' }),
};

export const base = {
  control: (styles: Styles) => ({
    ...styles,
    border: 'none',
    borderRadius: 5,
    background: 'rgba(255, 255, 255, 0.1)',
  }),
  option: (styles: Styles, { isFocused }: { isFocused: boolean }) => {
    return {
      ...styles,
      background: isFocused ? '#4282B0' : 'none',
      border: 'none',
      borderRadius: '0px',
      color: 'white',
      marginBottom: '0px',
      marginTop: '0px',
      paddingBottom: '5px',
      paddingTop: '5px',
    };
  },
  menu: (base: Styles) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
    background: 'rgba(14, 14, 14, 0.8)',
    color: 'white',
    borderRadius: '0px',
    paddingBottom: '0px',
    paddingTop: '0px',
  }),
  singleValue: (base: Styles) => ({ ...base, color: 'white' }),
  input: (base: Styles) => ({ ...base, color: 'white' }),
};

export const user = {
  control: (styles: Styles) => ({
    ...styles,
    border: 'none',
    borderRadius: '0px',
    background: 'rgba(50, 50, 50, 0)',
  }),
  option: (styles: Styles, { isFocused }: { isFocused: boolean }) => {
    return {
      ...styles,
      background: isFocused ? '#4282B0' : 'none',
      border: 'none',
      borderRadius: '0px',
      color: 'white',
      marginBottom: '0px',
      marginTop: '0px',
      paddingBottom: '5px',
      paddingTop: '5px',
    };
  },
  menu: (base: Styles) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
    background: 'rgba(14, 14, 14, 0.8)',
    color: 'white',
    borderRadius: '0px',
    paddingBottom: '0px',
    paddingTop: '0px',
  }),
  singleValue: (base: Styles) => ({ ...base, color: 'white' }),
  input: (base: Styles) => ({ ...base, color: 'white' }),
};

export const networks = {
  control: (styles: Styles) => ({ ...styles, background: 'rgba(0, 0, 0, 0)' }),
  option: (styles: Styles, { isFocused }: { isFocused: boolean }) => {
    return {
      ...styles,
      background: isFocused ? 'green' : 'none',
      border: 'none',
      color: 'white',
    };
  },
  menu: (base: Styles) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
    background: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
  }),
  singleValue: (base: Styles) => ({ ...base, color: 'white' }),
};
