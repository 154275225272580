import { useDispatch } from 'react-redux';
import ApproveTx from './ApproveTx';
import RejectTx from './RejectTx';
import { formatNetwork } from '../../../../utils/networkTools';
import { currentComponent, showModal } from '../../../../store/modal';
import { toast } from 'react-hot-toast';
import { useUser } from '../../../../hooks/useUser';
const TxActions = ({ tx }) => {
  const dispatch = useDispatch();
  const { user: { email } } = useUser();

  const buttonL =
    'bg-gray-500 p-3 w-full text-center mr-2 rounded bg-opacity-30 cursor-pointer hover:bg-opacity-50 border border-transparent hover:border-green-500';
  const buttonR =
    'bg-gray-500 p-3 w-full text-center ml-2 rounded bg-opacity-30 cursor-pointer hover:bg-opacity-50 border border-transparent hover:border-red-500';

  const displayApprove = () => {
    if (email === tx.requestingManager) {
      return toast.error('Unauthorized.');
    }
    dispatch(
      currentComponent(() => (
        <ApproveTx
          id={tx.txId}
          owner={tx.ownerId}
          amt={`${tx.amount} ${formatNetwork(tx.network)}`}
        />
      ))
    );
    dispatch(showModal());
  };

  const displayReject = () => {
    if (email === tx.requestingManager) {
      return toast.error('Unauthorized.');
    }
    dispatch(currentComponent(() => <RejectTx id={tx.txId} />));
    dispatch(showModal());
  };

  if (tx.approval.status === 'pending') {
    return (
      <div className="flex justify-evenly">
        <div onClick={displayApprove} className={buttonL}>
          Approve
        </div>
        <div onClick={displayReject} className={buttonR}>
          Reject
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default TxActions;
