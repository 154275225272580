import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createNewTransaction, getTransactions } from '../../../../store/transactions';
import { icon } from '../../../../config/tailwind.classnames';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { validateAddress } from '../../../../utils/networkTools';
import capitalize from '../../../../utils/capitalize';
import { networks } from '../../../../config/select.styles';
import { getBalances, getWalletById } from '../../../../store/wallets';
import { formatNetwork } from '../../../../utils/networkTools';
import { useUser } from '../../../../hooks/useUser';

function NewTransaction() {
  const { id } = useParams();
  const { user: { email, role, organization } } = useUser();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [managerEmail] = useState(email);
  const [ownerAddresses, setOwnerAddresses] = useState({
    bitcoin: null,
    ethereum: null,
    avalanche: null,
    polymesh: null,
    stellar: null,
    solana: null,
    ripple: null,
    aptos: null,
    cardano: null,
    polkadot: null,
  });
  const [ownerNetworks, setOwnerNetworks] = useState([]);

  const [formData, setFormData] = useState({
    walletId: id,
    orgId: organization,
    requester: email,
    direction: 'withdraw',
    amount: '',
    network: '',
    asset: '',
    destinationAddress: '',
  });

  const wallet = useSelector(state => state?.wallets && state.wallets[id]);

  const balances = useSelector(state => state?.wallets[id]?.balances);

  const getBalanceForAsset = (network, asset) => {
    return (Array.isArray(balances) && balances || [])
      .find((balance) =>
        balance.asset === asset.toLowerCase()
            &&
        balance.network === network.toLowerCase()
      )?.balance || '0';
  }

  const currentBalance = getBalanceForAsset(formData.network, formData.asset);

  useEffect(() => {
    if (role === 'admins') {
      toast.dismiss();
      toast.error('Not authorized to create transactions as admin.');
      navigate('/dashboard');
      return;
    }  
    const controller = new AbortController();
  
    (async () => {
      try {
        await dispatch(getWalletById(controller.signal, id));
      } catch (error) {
          console.error(error);
      }
    })();
  
    return () => controller.abort();
  }, [dispatch, id, navigate, role]);

  useEffect(() => {
    const controller = new AbortController();
    if (wallet) {
      if (!wallet.active) {
        toast.dismiss();
        toast.error('Wallet has been disabled. Contact an administrator.');
        navigate('/dashboard/wallets');
      }
      const ownerAddresses = {};
      for (const network in wallet.accounts) {
        ownerAddresses[network] = wallet.accounts[network]?.address || null;
      }
      setOwnerAddresses(() => ownerAddresses);

      const _networks = new Set();

      for (const network in wallet.accounts) {
        const account = wallet.accounts[network];
        if (account.active) {
          for (const token of account.assets) {
            _networks.add({
              name: 'network',
              value: token + '-' + network,
              label: `${token.toUpperCase()} (${capitalize(network)})`
            });
          }
        }
      }

      setOwnerNetworks(Array.from(_networks));

      if (!balances) {
        dispatch(
          getBalances(controller.signal, {
            walletId: id,
            networks: Array.from(_networks).map(network => network.value),
          }),
        )
      }
    }

    return () => setOwnerNetworks([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  const handleSubmit = e => {
    e.preventDefault();
    toast.dismiss();
    toast.loading('Working...');

    const { amount, asset, network, destinationAddress } = formData;

    if (!validateAddress(destinationAddress, network)) {
      toast.dismiss();
      if (network) toast.error(`Invalid ${capitalize(network)} address.`);
      else toast.error('Invalid token and destination address.');
      setFormData(prev => ({ ...prev, destinationAddress: '' }));
      return;
    }

    if (!amount || amount <= 0 || !network || !asset || !destinationAddress || !balances) {
      toast.dismiss();
      if (!amount) toast.error('Invalid amount');
      if (amount <= 0) toast.error('Amount must be greater than 0.');
      if (!network) toast.error('Please select which account network to use.');
      if (!destinationAddress) toast.error('Please enter a destination address.');
      if (!balances) toast.error('Please wait for wallet balance to load...');
      if (!asset) toast.error('Invalid asset');
      return;
    }

    if (amount > balances[formatNetwork(formData.network)]) {
      toast.dismiss();
      return toast.error('The amount you entered is greater than your account balance. Try again.');
    }

    dispatch(createNewTransaction(formData))
      .then(async res => {
        toast.dismiss();

        if (!res || res?.error) {
          toast.error(`Error with submitting new transaction: ${res?.error}`);
        } else {
          toast.loading('Creating transaction...');
          const { txId } = res;
          await dispatch(getTransactions());

          setTimeout(() => {
            toast.dismiss();
            navigate(`/dashboard/withdrawals/${txId}`);
            toast.success('Transaction created successfully!');
          }, 1500);
        }
      })
      .catch(e => {
        toast.dismiss();
        toast.error(`Error with creating new transaction: ${e?.message}`);
      });
  };

  const onChange = e => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

  const onBlur = e => {
    if (currentBalance && formData.network) {
      console.log(currentBalance)
      if (currentBalance === 0) return toast.error('Account balance is zero.');

      if (e.target.value > +currentBalance) {
        e.target.value = currentBalance;
        setFormData(prev => ({ ...prev, amount: currentBalance }));
        toast.error(`No enough balance found for ${formData.asset.toUpperCase()}.`);
      } else if (e.target.value <= 0) {
        toast.error('Please enter an amount greater than 0.');
        e.target.value = 0;
        setFormData(prev => ({ ...prev, amount: 0 }));
      }
    }
  };

  const handleSelect = e => {
    const [selectedAsset, selectedNetwork] = e.value.split('-');
    if (balances) {
      const balance = getBalanceForAsset(selectedNetwork, selectedAsset);
      if (balance) {
        if (!parseFloat(balance.replace(/,/g, ''))) {
          toast.error(`${selectedAsset.toUpperCase()} balance is zero.`);
        }
      } else {
        toast.error(`No balance found for ${selectedAsset.toUpperCase()}.`);
      }
    }
  
    setFormData(prev => ({
      ...prev,
      network: selectedNetwork,
      asset: selectedAsset,
    }));
  };

  const goBack = (walletId) => {
    if (walletId) {
      navigate(`/dashboard/wallets/${walletId}`);
    } else {
      navigate('/dashboard/wallets');
    }
  };

  return (
    <div className="flex justify-center mt-24">
      <form onSubmit={handleSubmit} className="w-full bg-gray-900 p-10 rounded max-w-4xl">
        <div className="flex justify-between mb-5">
          <h1 tabIndex={0} className="focus:outline-none text-3xl font-bold text-gray-100">
            Create a Transaction
          </h1>
        </div>
        <div className="flex flex-col justify-between rounded text-sm bg-gray-800 p-5">
          <div className="text-sm font-light text-gray-200 text-right flex justify-between">
            <span className="text-gray-500 mr-2">Manager (You):</span>
            <span className="min-w-[200px]">{managerEmail}</span>
          </div>
          <div className="text-sm font-light text-gray-200 text-right flex justify-between">
            <span className="text-gray-500 mr-2">Wallet ID:</span>
            <span className="min-w-[200px]">{id}</span>
          </div>
          <div className="text-sm font-light text-gray-200 text-right flex justify-between">
            <span className="text-gray-500 mr-2">Type:</span>
            <span className="min-w-[200px]">Withdraw</span>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-y-8 pt-10 items-center">
          <label className="text-sm text-gray-200 h-full flex items-center">Amount</label>
          <div className="col-span-2 flex h-full justify-evenly w-full items-center">
            <input
              disabled={!formData.network}
              placeholder={!formData.network ? 'select network first' : 'enter amount'}
              required
              onChange={onChange}
              onBlur={onBlur}
              name="amount"
              type="number"
              value={formData.amount}
              tabIndex={0}
              className="text-right flex text-sm bg-transparent text-white p-3 h-10 border-b mr-3 w-full"
            />
            <Select
              name="network"
              className="w-72 text-sm"
              onChange={handleSelect}
              options={ownerNetworks}
              styles={networks}
              placeholder="Token"
            />
          </div>
          <label className="text-sm text-gray-200 h-full flex items-center">To</label>
          <input
            onChange={onChange}
            value={formData.destinationAddress}
            name="destinationAddress"
            placeholder="Destination Address"
            type="text"
            className="col-span-2 bg-transparent text-sm text-gray-200 p-3 border-b"
          />

          {ownerAddresses[formData.network] && (
            <>
              <label className="text-sm text-gray-200 h-full flex items-center">From</label>
              <div className="flex flex-col col-span-2 bg-transparent text-sm text-gray-400">
                <div>{ownerAddresses[formData.network]}</div>
                {balances ? (
                 <div>
                 {`Balance: ${currentBalance} ${formData.asset.toUpperCase()} (${capitalize(formData.network)})`}
               </div>
                ) : (
                  <div className="animate-[pulse_1s_ease-in-out_infinite]">Balance loading...</div>
                )}
              </div>
            </>
          )}
        </div>

        <div className="w-full mt-8 flex justify-evenly">
          <button
            className={`${icon.green} mr-3 hover:bg-gradient-to-br hover:from-green-400 hover:to-green-500/75`}
            type="submit"
          >
            Create Transaction
          </button>
          <button className={`${icon.grey} !text-white ml-3`}  onClick={() => goBack(id)} type="button">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewTransaction;
