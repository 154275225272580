import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../store/modal';
import Timer from '../../utils/Timer';

const FirstLogin = ({ userState }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const errorInitialState = {
    phoneNumber: null,
    newPassword: false,
    confirmNewPassword: false,
    pwMismatch: null,
    invalidPassword: null,
  };

  const [errors, setErrors] = useState(errorInitialState);
  const dispatch = useDispatch();
  const buttonClass =
    'w-64 bg-gray-600 text-white p-5 rounded mt-5 hover:bg-green-600 transition duration-200 shadow-xl';

  const handleFirstLogin = async e => {
    e.preventDefault();
    setErrors(errorInitialState);

    if (!newPassword || !confirmNewPassword || newPassword !== confirmNewPassword) {
      // Password errors:
      if (!newPassword) setErrors(prev => ({ ...prev, newPassword: true }));
      if (!confirmNewPassword) setErrors(prev => ({ ...prev, confirmNewPassword: true }));
      if (newPassword !== confirmNewPassword)
        setErrors(prev => ({ ...prev, pwMismatch: 'Passwords do not match.' }));

      setNewPassword('');
      setConfirmNewPassword('');
    } else {
      if (checkValidPassword(newPassword)) {
        // Successful password submission
        Auth.completeNewPassword(userState, newPassword)
          .then(() => toast.success('Thank you! Please login with your new credentials.'))
          .then(() => dispatch(hideModal()))
          .catch(e => console.error(`Initial login error: ${e}.`));
      } else {
        // Password complexity not enough
        setErrors(prev => ({
          ...prev,
          invalidPassword:
            'Your password must have the following: Minimum length of 14, number, lowercase, uppercase, special character.',
        }));
      }
    }
  };

  const checkValidPassword = password => {
    const pwRequirements =
      /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{14,}/g;
    return pwRequirements.test(password);
  };

  return (
    <div className="absolute flex w-full justify-center text-white top-0 bottom-0 left-0 right-0">
      <div className=" min-w-fit max-w-[800px] w-full p-8 bg-opacity-90 rounded-lg flex flex-col items-center justify-center">
        <div className="items-center relative justify-center flex bg-gray-700 opacity-80 text-sm rounded-t-lg p-3 w-full shadow-offsetWhite">
          <span className="mr-2">session ends in:</span>
          <Timer seconds={180} />
        </div>
        <form
          onSubmit={handleFirstLogin}
          className="w-full h-128 relative items-center justify-evenly flex flex-col bg-gray-800 opacity-80 rounded-b-lg p-5"
        >
          {errors.pwMismatch && (
            <div className="shadow-innerWhite text-white bg-red-400 bg-opacity-30 w-128 p-3 rounded text-sm flex text-center justify-center">
              <div className="flex justify-center text-center">{errors.pwMismatch}</div>
            </div>
          )}
          {errors.invalidPassword && (
            <div className="shadow-innerWhite text-white bg-red-400 bg-opacity-30 w-128 p-3 rounded text-sm flex justify-center">
              <div className="flex justify-center text-center">{errors.invalidPassword}</div>
            </div>
          )}
          <div className="flex items-center m-5">
            <label className="w-128">New Password</label>
            <input
              className={`${
                errors.newPassword ? 'border-2 border-red-500' : ''
              } rounded w-full h-8 p-3 text-sm bg-gray-700`}
              type="password"
              name="newPassword"
              placeholder={errors.newPassword ? 'Please enter a new password' : ''}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center m-5">
            <label className="w-128">Confirm New Password</label>
            <input
              className={`${
                errors.confirmNewPassword ? 'border-2 border-red-500' : ''
              } rounded w-full h-8 p-3 text-sm bg-gray-700`}
              type="password"
              name="confirmNewPassword"
              placeholder={errors.newPassword ? 'Please confirm your new password' : ''}
              value={confirmNewPassword}
              onChange={e => setConfirmNewPassword(e.target.value)}
            ></input>
          </div>
          <div className="w-full justify-evenly flex">
            <button type="submit" className={buttonClass}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FirstLogin;
