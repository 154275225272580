export const baseCard =
  'bg-gradient-to-br from-[#FFFFFF40] via-[#FFFFFF30] to-[#FFFFFF10] backdrop-blur-md rounded shadow p-8 text-white min-w-fit';

export const icon = {
  approve:
    'hover:bg-gradient-to-br from-[#2DA8FF80] via-[#45FF7F80] to-[#39FF0080] mr-2 cursor-pointer',
  reject: 'hover:bg-gradient-to-br from-[#2da8ff3b] via-[#FF000080] to-[#FF088090] cursor-pointer',
  pending:
    'shadow-xl shadow-innerWhite hover:shadow-innerWhiteHover flex justify-center text-xs border-white border-x border-y rounded-lg items-center w-full h-10 pl-10 pr-10 cursor-pointer ',
  action:
    'transition rounded justify-center duration-300 ease backdrop-blur-md shadow border border-white text-center font-medium text-white py-3 px-10 hover:text-white',
  green:
    'text-white p-5 bg-gradient-to-br from-green-600 to-green-700/75 mt-2 rounded w-full text-sm transition-all duration-200 ease-in-out',
  grey: 'text-gray-500 p-5 bg-gradient-to-br from-slate-600/25 to-slate-700/75 mt-2 rounded w-full text-sm hover:bg-gradient-to-br hover:from-slate-500 hover:to-slate-600/50 hover:text-white transition-all duration-200 ease-in-out',
  user: {
    active:
      'relative bg-red-800 hover:bg-red-700 p-3 rounded-lg w-full text-sm flex items-center justify-center m-0.5 min-w-[80px]',
    inactive:
      'relative bg-gray-800 hover:bg-gray-700 p-3 rounded-lg w-full text-sm flex items-center justify-center m-0.5 min-w-[80px]',
    admin:
      'bg-gradient-to-r from-neutral-900/20 to-lime-400/60 text-sm pl-24 pr-10 py-2 items-center flex',
    super:
      'bg-gradient-to-r from-neutral-900/25 to-red-800/75 text-sm pl-24 pr-10 py-2 items-center flex',
    investor:
      'bg-gradient-to-r from-[#FFFFFF30]/10 to-sky-800/75 text-sm pl-24 pr-10 py-2 items-center flex',
  },
  visible:
    'bg-gradient-to-br from-green-600 to-teal-800 hover:from-green-600/50 hover:to-teal-800/50 mr-5 p-3 rounded w-[200px] flex cursor-pointer',
  invisible:
    'bg-gray-700 mr-5 p-3 rounded w-[200px] flex bg-opacity-80 cursor-pointer hover:bg-opacity-30 hover:bg-green-700',
};

export const txApproval =
  'bg-gradient-to-br from-emerald-400/25 via-green-400/75 to-green-700 w-2 border-x border-white';
export const pendingApproval =
  'bg-gradient-to-br from-gray-500/25 via-gray-600/75 to-gray-800/50 w-2 border-x border-white';
export const txReject =
  'bg-gradient-to-br from-red-500/25 via-red-600/75 to-red-800/50 w-2 border-x border-white';
