import {
  HomeIcon,
  WalletIcon,
  UserGroupIcon,
  ArrowTrendingUpIcon,
  IdentificationIcon,
  ArrowLeftOnRectangleIcon,
  ArrowUpRightIcon,
  ArrowDownLeftIcon,
} from '@heroicons/react/24/outline';

const home = {
  name: 'Home',
  path: '/dashboard',
  icon: <HomeIcon className="w-6" />,
};
const withdrawals = {
  name: 'Withdrawals',
  path: '/dashboard/withdrawals',
  icon: <ArrowUpRightIcon className="w-6" />,
};
const deposits = {
  name: 'Deposits',
  path: '/dashboard/deposits',
  icon: <ArrowDownLeftIcon className="w-6" />,
};
const wallets = {
  name: 'Wallets',
  path: '/dashboard/wallets',
  icon: <WalletIcon className="w-6" />,
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const staking = {
  name: 'Staking',
  path: '/dashboard/staking',
  icon: <ArrowTrendingUpIcon className="w-6" />,
};
const organization = {
  name: 'Organization',
  path: '/dashboard/organization',
  icon: <UserGroupIcon className="w-6" />,
};
const settings = {
  name: 'Account',
  path: '/dashboard/settings',
  icon: <IdentificationIcon className="w-6" />,
};
const logout = {
  name: 'Logout',
  path: '/dashboard/logout',
  icon: <ArrowLeftOnRectangleIcon className="w-6 text-red-500" />,
};

const routes = {
  superadmins: [home, withdrawals, deposits, wallets, organization, settings, logout],
  admins: [home, withdrawals, deposits, wallets, organization, settings, logout],
  investors: [home, withdrawals, deposits, wallets, organization, settings, logout],
};

export default routes;
