import { useMatch, useResolvedPath, useNavigate } from 'react-router-dom';

function CustomLink({ name, to, icon, minimized }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname });
  const navigate = useNavigate();

  const className = () => {
    let classes = new Set('transition-transform flex items-center rounded h-16 w-full'.split(' '));
    if (match) {
      classes.add('cursor-default');
      name === 'Logout' ? classes.add('bg-red-900') : classes.add('bg-gray-700');
    } else {
      classes.add('text-gray-400');
    }
    name === 'Logout' ? classes.add('hover:bg-red-800') : classes.add('hover:bg-gray-600');
    name === 'Logout' ? classes.add('hover:text-red-200') : classes.add('hover:text-white');

    return Array.from(classes).join(' ');
  };

  return (
    <li className="flex w-full text-md justify-between cursor-pointer items-center mb-1">
      <div className="flex items-center justify-center w-full">
        <button type="button" onClick={() => navigate(to)} className={`${className()}`}>
          <div className="flex w-full mx-3">
            <span
              className={`inline-block transition-all transform duration-150 delay-75 ${
                minimized ? 'translate-x-[70%]' : 'translate-x-0'
              }`}
            >
              {icon}
            </span>
            <span
              className={`ml-5 transition-all ${
                minimized ? '-translate-x-10 absolute opacity-0' : 'opacity-1 duration-150 delay-75'
              }`}
            >
              {name}
            </span>
          </div>
        </button>
      </div>
    </li>
  );
}

export default CustomLink;
