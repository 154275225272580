import { useSelector } from 'react-redux';
import { ArrowDownOnSquareStackIcon } from '@heroicons/react/24/outline';
import { JSX } from 'react';

function jsonFileDownload(fileName: string, jsonData: Record<string, any>) {
  const data = new Blob([JSON.stringify(jsonData)], { type: 'text/json' });
  const jsonURL = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = jsonURL;
  link.setAttribute('download', fileName);
  link.click();
  document.body.removeChild(link);
}

export type JSONFileDownloadParams = { title: string; data: Record<string, any> };

export function JSONFileDownload({ title, data }: JSONFileDownloadParams): JSX.Element {
  const user = useSelector((state: any) => state.session.user);
  const org = user?.organization || 'ORGNOTFOUND';
  const fileName = `${org}_${title}_${new Date().toISOString()}.json`;
  return (
    <button
      className="flex bg-gray-700 rounded px-2 py-1 hover:bg-gray-600 text-xs items-center text-center"
      onClick={() => jsonFileDownload(fileName, data)}
    >
      <ArrowDownOnSquareStackIcon className="w-6 pr-1" />
      JSON ({title})
    </button>
  );
}

export default JSONFileDownload;
