import { ChangeEvent, FormEvent } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface Props {
  query: string;
  set: (e: string) => void;
}

const UserSearch = ({ query, set }: Props) => {
  return (
    <form className="flex items-center text-sm" onSubmit={(e: FormEvent) => e.preventDefault()}>
      <span className="flex pt-5 space-x-2 w-full">
        <MagnifyingGlassIcon className="w-4" />
        <input
          className="w-full bg-transparent border-b focus:outline-none"
          value={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) => set(e.target.value)}
          placeholder="Search users"
        />
      </span>
    </form>
  );
};

export default UserSearch;
