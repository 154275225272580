import {
  DoubleLeft,
  SingleLeftWithDecrement,
  SingleRightWithIncrement,
  DoubleRight,
} from '../assets/icons/arrows';

export type PageNavigationProps = {
  prev: () => void;
  next: () => void;
  curr: number;
  max: number;
  jump: (page: number) => void;
  setDisplayPageNumber: (page: number | ((page: number) => void)) => void;
  displayPageNumber: number;
};

export function PageNavigation(props: PageNavigationProps) {
  const { prev, jump, next, curr, max, displayPageNumber, setDisplayPageNumber } = props;

  return (
    <div className="flex items-center justify-between w-full mt-5 text-sm text-white pb-2 pl-4 pr-4 pt-0">
      <div className="flex">
        <DoubleLeft
          first={() => {
            setDisplayPageNumber(1);
            jump(1);
          }}
        />
        <SingleLeftWithDecrement
          decrement={() => {
            if (curr <= 1) return;
            setDisplayPageNumber(curr => curr - 1);
            prev();
          }}
        />
      </div>
      <div className="flex items-center">
        <span>Page</span>
        <input
          className="bg-transparent w-10 ml-3 border rounded text-center focus:outline-none select-all"
          value={displayPageNumber}
          onFocus={e => e.target.select()}
          onBlur={() => setDisplayPageNumber(Math.min(max, displayPageNumber) || 1)}
          onChange={e => {
            const val = Number(e.target.value);
            const isNaturalNumber = val > 0 && val.toString().indexOf('.') < 0;
            if (e.target.value !== '' && !isNaturalNumber) return;
            setDisplayPageNumber(val);
          }}
        />
      </div>
      <div className="flex">
        <SingleRightWithIncrement
          increment={() => {
            if (curr >= max) return;
            setDisplayPageNumber(curr => curr + 1);
            next();
          }}
        />
        <DoubleRight
          last={() => {
            setDisplayPageNumber(max);
            jump(max);
          }}
        />
      </div>
    </div>
  );
}

export default PageNavigation;
