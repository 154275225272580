import etana from '../../assets/Etana/white.svg';

function LoadingPage() {
  return (
    <div className="p-10">
      <section className="h-screen">
        <div className="mx-auto flex justify-center lg:items-center h-full">
          <div className="pt-0 px-2 flex flex-col items-center justify-center">
            <img
              className="w-49 h-auto px-12 md:px-56 lg:px-80 xl:px-[32rem] animate-pulse"
              src={etana}
              alt="Etana Digital"
            />
            <h1 className="text-2xl font-bold text-white animate-pulse">Loading...</h1>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoadingPage;
