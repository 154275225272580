const IdlePrompt = ({ props }) => {
  const { showPrompt, remaining, handleActivate } = props;
  return (
    <div
      className={`${
        showPrompt ? 'opacity-1' : 'opacity-0 absolute'
      } p-5 text-sm duration-500 transition-all animate-[fade2_5s_ease-in-out_infinite] shadow-innerWhite bg-opacity-10 mx-2 -z-[90]`}
    >
      <div>You are now idle.</div>
      <div className="text-xs">
        Logging you out in: 00:{remaining > 9 ? remaining : `0${remaining}`}
      </div>
      <button
        className="bg-gray-800 hover:bg-gray-700 rounded p-3 mt-5"
        type="button"
        onClick={() => handleActivate()}
      >
        Still here?
      </button>
    </div>
  );
};

export default IdlePrompt;
