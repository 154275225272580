import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeUserSession } from '../../../store/session';
import { toast } from 'react-hot-toast';

export default function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    const loadingId = toast.loading('Logging out');
    Promise.resolve(dispatch(removeUserSession()))
      .then(() => navigate('/login'))
      .catch(e => toast.error(`Failed to log out: ${e}`))
      .finally(() => toast.dismiss(loadingId));
  };

  const buttonStyle = 'p-5 rounded-lg text-white hover:shadow-lg cursor-pointer';
  const buttonRed = `${buttonStyle} bg-gradient-to-br from-red-700 to-red-800/75 hover:bg-red-600`;
  const buttonZinc = `${buttonStyle} bg-gradient-to-br from-zinc-600 to-zinc-700/75 hover:bg-zinc-400`;
  return (
    <div className="flex justify-center p-5">
      <div className="bg-gradient-to-br from-[#FFFFFF40] via-[#FFFFFF30] to-[#FFFFFF10] backdrop-blur-md rounded shadow p-8 text-white w-fit">
        <div className="grid py-10 space-y-4 md:space-y-10">
          <h1 className="text-md md:text-2xl font-semibold text-white">
            Are you sure you want to logout?
          </h1>
          <div className="flex justify-evenly">
            <button className={buttonRed} onClick={handleLogout}>
              Logout
            </button>
            <button className={buttonZinc} onClick={() => navigate('/dashboard')}>
              Go Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
