import { useState } from 'react';

const usePaginate = (data: any[], perPage: number) => {
  const [current, setCurrent] = useState<number>(1);

  const max = Math.ceil(data?.length / perPage) || 1;

  const from = data?.length ? (current - 1) * perPage + 1 : 0;

  let to;
  if (!data?.length) to = 0;
  else if (current === max) to = from + data?.length - (current - 1) * perPage - 1;
  else to = current * perPage;

  const currentData = () => data?.slice((current - 1) * perPage, current * perPage);
  const next = () => setCurrent(curr => Math.min(curr + 1, max));
  const prev = () => setCurrent(curr => Math.max(curr - 1, 1));
  const jump = (page: number) => setCurrent(() => Math.min(Math.max(page, 1), max));

  const page = { data: currentData, from, to, current, max };

  const set = (item: any, index: number) => data[index] = item;

  return { prev, jump, next, page, set };
};

export default usePaginate;
