import { SHOW_MODAL, HIDE_MODAL, SET_ACTIVE_COMPONENT, SET_MODAL } from './constants';

export const showModal = () => ({ type: SHOW_MODAL });
export const hideModal = () => ({ type: HIDE_MODAL });
export const currentComponent = component => ({ type: SET_ACTIVE_COMPONENT, component });
export const modalMount = modal => ({ type: SET_MODAL, modal });

const modalReducer = (state = { current: null, mount: null, show: false }, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, show: true };
    case HIDE_MODAL:
      return { ...state, show: false };
    case SET_ACTIVE_COMPONENT:
      return { ...state, current: action.component };
    case SET_MODAL:
      return { ...state, mount: action.modal };
    default:
      return state;
  }
};

export default modalReducer;
