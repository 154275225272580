import { StrictMode, useRef, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom/client';

import App from './App';
import './index.css';

import store from './store';
import { modalMount } from './store/modal';

const Root = () => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  useEffect(() => {
    dispatch(modalMount(modalRef.current));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <App />
      <div ref={modalRef} className="modal"></div>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </StrictMode>
);
