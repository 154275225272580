import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const isDisabled = 'flex text-gray-700 opacity-25';
const isEnabled = 'flex text-white hover:text-green-600 hover:cursor-pointer';

export const DoubleLeft = ({ first }) => {
  return (
    <div className={first ? isEnabled : isDisabled} onClick={() => (first ? first() : '')}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
        />
      </svg>
    </div>
  );
};

// const SingleLeft = ({ decrement }: { decrement: () => void | null }) => {
//   return (
//     <div
//       className={`flex
//         ${
//         decrement
//           ? 'text-white hover:text-green-600 hover:cursor-pointer'
//           : 'text-gray-700 opacity-25'
//       }
//       `}
//       onClick={() => Boolean(decrement) && decrement()}
//     >
//       <ChevronLeftIcon className="w-4" />
//     </div>
//   );
// };

export const SingleLeftWithDecrement = ({ decrement }) => {
  return (
    <div
      className={decrement ? isEnabled : isDisabled}
      onClick={() => (decrement ? decrement() : '')}
    >
      <ChevronLeftIcon className="w-5" />
    </div>
  );
};

export const SingleRightWithIncrement = ({ increment }) => {
  return (
    <div
      className={increment ? isEnabled : isDisabled}
      onClick={() => (increment ? increment() : '')}
    >
      <ChevronRightIcon className="w-5" />
    </div>
  );
};

export const DoubleRight = ({ last }) => {
  return (
    <div className={last ? isEnabled : isDisabled} onClick={() => (last ? last() : '')}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
        />
      </svg>
    </div>
  );
};
