import Cookies from 'universal-cookie';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { urls } from '../config';

async function axiosRequest(
  url: string,
  options: Partial<AxiosRequestConfig> | null = null,
): Promise<AxiosResponse<any, any>> {
  const cookies = new Cookies();
  options ||= {};
  options.method ||= 'GET';
  options.timeout ||= 30000;
  options.baseURL = urls.apiUrl;
  options.withCredentials = true;
  options.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookies.get('jwt_token')}`,
  };

  return axios.request({ url, ...options });
}

export async function httpGet(url: string) {
  const controller = new AbortController();
  const opts = { signal: controller.signal };
  const res = await axiosRequest(url, opts);
  if (!res || !res.status) {
    throw new Error('No response');
  }
  if (res.status >= 300) {
    throw new Error(res.data || res.status);
  }
  return res.data;
}

export default axiosRequest;
