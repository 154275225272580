import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../../../store/modal';
import { addAsset, getWalletAssets } from '../../../../store/wallets';
import { getWalletById, getBalances } from '../../../../store/wallets';
import { icon } from '../../../../config/tailwind.classnames';
import { NetworkLogo } from '../../../../assets/networks';
import { getAvailableAssets } from '../../../../utils/assets';

const AddAsset = ({ id }) => {
  const [availableAssets, setAvailableAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [activeHover, setActiveHover] = useState(null);

  const wallet = useSelector(state => state?.wallets && state.wallets[id]);
  const wallets = useSelector(state => state.wallets);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!wallets.assets) {
      return;
    }
    const controller = new AbortController();
    dispatch(getWalletById(controller.signal, id))
      .then(res => {
        res && res.accounts && setAvailableAssets(getAvailableAssets(wallets.assets, res.accounts));
      })
      .catch(e => console.log(e));
    return () => controller.abort();
  }, [dispatch, id, wallets.assets]);

  useEffect(() => {
    dispatch(getWalletAssets({})).catch(e => {
      console.log(e);
      toast.error(`Error getting wallet assets: ${e}`);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!wallet.active) {
      toast.dismiss();
      toast.error('Wallet has been disabled. Contact an administrator.');
      dispatch(hideModal());
    }
  }, [wallet, dispatch]);

  const assetHover = n => setActiveHover(n);

  const handleAssetClick = assetData => {
    setSelectedAssets(prevSelected => {
      if (prevSelected.some(asset => asset.id === assetData.id)) {
        return prevSelected.filter(asset => asset.id !== assetData.id);
      }
      return [...prevSelected, assetData];
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (selectedAssets.length === 0) {
      toast.error('Please choose at least one asset to add.');
    } else {
      toast.loading('Submitting request...');
      dispatch(
        addAsset({
          walletId: id,
          assets: selectedAssets.map(asset => ({
            network: asset.network,
            asset: asset.asset,
          })),
        }),
      )
        .then(async () => {
          const controller = new AbortController();
          dispatch(getBalances(controller.signal, { walletId: id }));
        })
        .then(_ => {
          toast.dismiss();
          toast.success('Assets added successfully!');
        })
        .then(() => dispatch(hideModal()))
        .catch(err => toast.error(err?.message));
    }
  };

  if (!wallet) {
    return null;
  }

  return (
    <div className="text-white">
      <div className="bg-gray-900 bg-opacity-90 rounded-lg p-10 min-w-[300px]">
        <div className="flex text-sm space-x-2">
          <span className="text-gray-400">Wallet ID:</span>
          <span>{id}</span>
        </div>
        <div className="flex text-sm space-x-2">
          <span className="text-gray-400">Owner:</span>
          <span>{wallet.owner}</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="text-2xl lg:pt-10">Which asset would you like to add?</div>
          <div className="grid grid-cols-3 xl:grid-cols-5 gap-x-2 gap-y-2 py-4 lg:py-8">
            {availableAssets.map(assetData => (
              <div
                key={assetData.id}
                className={`text-center p-5 cursor-pointer rounded relative ${
                  selectedAssets.some(asset => asset.id === assetData.id)
                    ? 'bg-blue-800 hover:bg-blue-600'
                    : 'bg-slate-800 hover:bg-slate-600'
                }`}
                onClick={() => handleAssetClick(assetData)}
                onMouseEnter={() => assetHover(assetData.id)}
                onMouseLeave={() => assetHover(null)}
              >
                <div className="w-8 lg:w-12">
                  <NetworkLogo network={assetData.network} token={assetData.asset} size="s" />
                </div>
                <div
                  className={`absolute flex justify-center items-center bg-slate-900 bg-opacity-50 top-0 left-0 right-0 bottom-0 rounded ${
                    activeHover !== assetData.id && 'hidden'
                  }`}
                >
                  {assetData.asset?.toUpperCase()}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-evenly space-x-4">
            <button
              className={`${icon.green} hover:bg-gradient-to-br hover:from-green-400 hover:to-green-500/75`}
              type="submit"
            >
              Add Asset{selectedAssets.length > 1 ? 's' : ''}
            </button>
            <button
              className={`${icon.grey} text-white`}
              onClick={() => dispatch(hideModal())}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAsset;
