import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserTables from './UserTables';
import UserSearch from './UserSearch';
import { getUsers } from '../../../store/users';
import { Loading } from '../../../assets/icons/loading';
import { useUser } from '../../../hooks/useUser';

const OrgUsers = ({ userRef }) => {
  const users = useSelector(state => Object.values(state.users));
  const [searchData, setSearchData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    user: { groups, organization },
  } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (users.length) setIsLoaded(true);
  }, [users.length]);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getUsers(controller.signal));
  }, [dispatch]);

  useEffect(() => {
    if (!searchQuery) return; // This line prevents "Maximum update depth exceeded" error
    setSearchData(users.filter(u => u.email.toLowerCase().includes(searchQuery.toLowerCase())));
  }, [users, searchQuery]);

  return (
    <div className="w-full flex justify-center pt-5">
      <div className="min-w-fit">
        <div className="flex items-center justify-between">
          <p className="text-2xl text-gray-100">
            Organization Users
            <div className="text-lg text-gray-400">{organization}</div>
          </p>

          {/*Invite new users button*/}
          {(groups.includes('admins') || groups.includes('superadmins')) && (
            <button
              className="bg-gradient-to-br from-cyan-600 to-cyan-700/75 rounded p-2 hover:bg-green-400 text-sm flex items-center"
              onClick={() => navigate('new')}
            >
              <span>Invite New User</span>
            </button>
          )}
        </div>

        <UserSearch query={searchQuery} set={setSearchQuery} />
        <div className="py-4">
          {isLoaded ? (
            <div className="grid space-y-2">
              {['admins', 'investors'].map(group => (
                <UserTables
                  key={group}
                  group={group}
                  query={searchQuery}
                  users={
                    searchQuery && searchData
                      ? searchData.filter(
                          ({ groups }) => Array.isArray(groups) && groups.includes(group),
                        )
                      : users.filter(
                          ({ groups }) => Array.isArray(groups) && groups.includes(group),
                        )
                  }
                  userRef={userRef}
                />
              ))}
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrgUsers;
