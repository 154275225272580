import { baseCard } from '../../../config/tailwind.classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../store/modal';
import { CopyToClipboard } from '../../../assets/icons/icons';
import { NetworkLogo } from '../../../assets/networks';

function ExplorerLink({ deposit }) {
  if (!deposit.explorerUrl) {
    return <span>deposit.txHash</span>;
  }
  return (
    <span>
      <a
        href={deposit.explorerUrl}
        target="_blank"
        rel="noreferrer"
        className="text-blue-400 underline hover:text-blue-700"
      >
        {deposit.txHash}
      </a>
    </span>
  );
}

const DepositTable = ({ deposit }) => (
  <div className="pt-8 text-sm">
    <table className="w-full">
      <tbody>
        <tr className="border-t">
          <td className="pt-5 text-gray-400 w-48 min-w-[144px]">Wallet ID</td>
          <td className="pt-5 sm:text-xs md:text-xs lg:text-sm flex space-x-2">
            <span>
              <a
                href={`/dashboard/wallets/${deposit.walletId}`}
                className="text-blue-400 underline hover:text-blue-700"
              >
                {deposit.walletId}
              </a>
            </span>
            <span className="text-gray-400">
              <CopyToClipboard label="Wallet ID" text={deposit.walletId} />
            </span>
          </td>
        </tr>
        <tr>
          <td className="text-gray-400 w-48 min-w-[144px]">Owner</td>
          <td>{deposit.owner}</td>
        </tr>

        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Transaction Hash</td>
          <td className="py-1 sm:text-xs md:text-xs lg:text-sm flex items-center space-x-2">
            <ExplorerLink deposit={deposit} />
            <span className="text-gray-400">
              <CopyToClipboard label="Transaction Hash" text={deposit.txHash} />
            </span>
          </td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Amount</td>
          <td>{deposit.amount}</td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Asset</td>
          <td>{deposit.asset?.toUpperCase()}</td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Date Seen</td>
          <td>
            {new Date(deposit.seenAt)?.toLocaleString()} ({new Date(deposit.seenAt)?.toISOString()})
          </td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Block Number</td>
          <td>{deposit.blockNumber}</td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Origin</td>
          <td>{deposit.originAddress || deposit.originAddresses.join(', ')}</td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Destination</td>
          <td>{deposit.destinationAddress || deposit.destinationAddresses.join(', ')}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

function DepositCard({ deposit }) {
  const dispatch = useDispatch();

  return (
    <div className="h-max max-w-[1400px] !min-w-[500px] sm:px-6">
      <div
        className={`${baseCard} !min-w-[500px] sm:text-xs !p-10 !rounded-lg !bg-black shadow-3xl`}
      >
        <div className="flex justify-between">
          <div className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-100">
            <div className="flex items-center space-x-2">
              <span>Deposit Details</span>
              <span className="w-6">
                <NetworkLogo network={deposit.network} token={deposit.asset} size="s" />
              </span>
            </div>
          </div>
          <XMarkIcon onClick={() => dispatch(hideModal())} className="w-8 hover:cursor-pointer" />
        </div>
        <DepositTable deposit={deposit} />
      </div>
    </div>
  );
}

export default DepositCard;
