import axiosRequest from './axios';
import { GET_USERS, GET_MANAGERS } from './constants';
import { urls } from '../config';

// ACTION CREATORS
const userList = data => ({ type: GET_USERS, data });
const managerList = data => ({ type: GET_MANAGERS, data });

/**
 * @description - Get users (thunks)
 * @param signal
 * @throws
 * @returns {function(*, *): Promise<*>}
 */
export const getUsers = signal => async (dispatch, getState) => {
  const res = await axiosRequest(urls.userUrl, { signal });
  if (!res || res.status >= 300) {
    throw new Error('Error retrieving users.');
  }
  await dispatch(userList(res.data));
  return res.data;
};

export const getManagers = () => async (dispatch, getState) => {
  const res = await axiosRequest(urls.userUrl);
  if (!res || res.status >= 300) {
    throw new Error('Error retrieving users.');
  }
  const managers = res.data.filter(user => user.groups.includes('investors'));
  dispatch(managerList(managers));
  return managers;
};

// REDUCER
const userReducer = (state = {}, action) => {
  const newState = { ...state };
  switch (action.type) {
    case GET_USERS: {
      if (action.data) action.data.map(user => (newState[user.email] = user));
      return newState;
    }
    case GET_MANAGERS: {
      newState.managers = [];
      if (action.data) action.data.map(user => newState.managers.push(user.email));
      return newState;
    }
    default:
      return state;
  }
};

export default userReducer;
