import capitalize from './capitalize';
import {
  CheckIcon,
  EllipsisHorizontalIcon,
  LinkIcon,
  NoSymbolIcon,
  RocketLaunchIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { JSX } from 'react';

export type StatusTileProps = { status: string };

export function StatusTile({ status }: StatusTileProps): JSX.Element {
  const baseTile = 'py-1 px-3 bg-opacity-75 rounded text-xs flex items-center';

  switch (status) {
    case 'approved': {
      return (
        <div className={`${baseTile} bg-green-600`}>
          <CheckIcon className="w-4 pr-1" />
          <span>Approved</span>
        </div>
      );
    }
    case 'pending': {
      return (
        <div className={`${baseTile} bg-yellow-200 text-gray-700`}>
          <EllipsisHorizontalIcon className="w-4" />
          <span className="pl-1">Pending Approval</span>
        </div>
      );
    }
    case 'rejected': {
      return (
        <div className={`${baseTile} bg-red-500`}>
          <XMarkIcon className="w-4" />
          <span className="pl-1">Rejected</span>
        </div>
      );
    }
    case 'submitted': {
      return (
        <div className={`${baseTile} bg-blue-500`}>
          <RocketLaunchIcon className="w-4" />
          <span className="pl-1">Submitted to Blockchain</span>
        </div>
      );
    }
    case 'success': {
      return (
        <div className={`${baseTile} bg-green-600`}>
          <LinkIcon className="w-4" />
          <span className="pl-1">Successful</span>
        </div>
      );
    }
    case 'failure': {
      return (
        <div className={`${baseTile} bg-red-500`}>
          <XMarkIcon className="w-4" />
          <span className="pl-1">Failed</span>
        </div>
      );
    }
    case 'cancelled': {
      return (
        <div className={`${baseTile} bg-slate-900`}>
          <NoSymbolIcon className="w-4" />
          <span className="ml-1">Cancelled</span>
        </div>
      );
    }
    default:
      return <div className={baseTile}>{capitalize(status)}</div>;
  }
}

export default StatusTile;
