import { useEffect } from 'react';

/**
 * Hook that invokes a callback function when a click occurs outside of the specified ref.
 * @param ref Ref object representing the DOM element.
 * @param callback Callback function to be executed when a click occurs outside the element.
 */
function useClickOutside(ref: any,
    callback: () => void): void {
    useEffect(() => {
        /**
         * Handles click outside events.
         * @param event The click event object.
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Adds event listener to handle clicks outside the element.
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Removes event listener on cleanup.
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}

export default useClickOutside;
