import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSort from '../../../hooks/useSort';
import usePaginate from '../../../hooks/usePaginate';
import PageNavigation from '../../../utils/PageNavigation';
import { Loading } from '../../../assets/icons/loading';
import { baseCard } from '../../../config/tailwind.classnames';
import axiosRequest from '../../../store/axios';
import { urls } from '../../../config';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

import FilterEntries from './actions/FilterEntries';
import { HistoryHeader } from './HistoryHeader';
import { HistoryListItem } from './HistoryListItem';

const FilterByType = ({ filter, setFilter }: any) => {
  const handleChange = (e: any) => {
    const { name, checked } = e.target;
    setFilter((prev: any) => ({
      ...prev,
      type: { ...prev.type, [name]: checked },
    }));
  };

  return (
    <div className="flex flex-col space-y-1">
      <div className="text-white text-sm font-bold">Transaction Type</div>
      <div className="grid grid-cols-2">
        <label className="flex text-white justify-start text-sm space-x-4">
          <input
            type="checkbox"
            name="DEPOSIT"
            onChange={handleChange}
            checked={filter.type.DEPOSIT}
          />
          <span>Deposits</span>
        </label>
        <label className="flex text-white justify-start text-sm space-x-4">
          <input
            type="checkbox"
            name="WITHDRAW"
            onChange={handleChange}
            checked={filter.type.WITHDRAW}
          />
          <span>Withdraw</span>
        </label>
      </div>
    </div>
  );
};

/**
 * @description - Get transactions by calling the API. Just return the data, or throw if the call errors for some reason.
 * @throws
 * @param role
 * @returns - a slice of transactions
 */

async function getDeposits(role: string): Promise<any[]> {
  let url;
  switch (role) {
    case 'superadmins':
    case 'admins':
      url = `${urls.adminUrl}/deposits`;
      break;
    default:
      url = `${urls.apiUrl}/deposits`;
  }
  const controller = new AbortController();
  const opts = { signal: controller.signal };
  const res = await axiosRequest(url, opts);
  if (!res || !res.status) throw new Error('No response');
  if (res.status >= 300) throw new Error(`Error status ${res.status}`);
  return res.data;
}

/**
 * @description - Get transactions by calling the API. Just return the data, or throw if the call errors for some reason.
 * @throws
 * @param role - the user's preferred role
 * @returns - a slice of transactions
 */
// TODO: REUSE
async function getTransactions(role: string): Promise<any[]> {
  let url;
  switch (role) {
    case 'superadmins':
      url = `${urls.superAdminUrl}/transactions`;
      break;
    case 'admins':
      url = `${urls.adminUrl}/transactions`;
      break;
    default:
      url = urls.transactionUrl;
  }
  const controller = new AbortController();
  const opts = { signal: controller.signal };
  const res = await axiosRequest(url, opts);
  if (!res || !res.status) throw new Error('No response');
  if (res.status >= 300) throw new Error(`Error status ${res.status}`);
  return res.data;
}

type WalletHistoryListProps = {
  walletId: string;
};
export function WalletHistoryList({ walletId }: WalletHistoryListProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeSort, setActiveSort] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(walletId);
  const [searchCategory, setSearchCategory] = useState({ label: 'Wallet ID', key: 'walletId' });
  const [history, setHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);

  const { role } = useSelector((state: any) => state.session);

  // Sort and paginate transactions. Allow jumping to pages by page number.
  const { sortedItems, requestSort, sortConfig } = useSort(filteredHistory);
  const { prev, jump, next, page } = usePaginate(sortedItems, 20);
  const [displayPageNumber, setDisplayPageNumber] = useState(page.current);

  const [filter, setFilter] = useState({} as any);
  const [organizationVisibility, setOrganizationVisibility] = useState({
    ETANATRUST: true,
    BDACS: true,
  });

  useEffect(() => jump(displayPageNumber), [displayPageNumber, jump]);

  // Get deposits from API, which just queries the database
  useEffect(() => {
    const getHistory = async () => {
      const [deposits, transactions] = await Promise.all([
        getDeposits(role),
        getTransactions(role),
      ]);
      const history = [];
      for (const deposit of deposits) {
        history.push({
          ...deposit,
          walletId: deposit.walletId,
          seenAt: deposit.seenAt,
          network: deposit.network,
          asset: deposit.asset,
          amount: deposit.amount,
          address: deposit.originAddress,
          type: 'DEPOSIT',
        });
      }
      for (const transaction of transactions) {
        history.push({
          ...transaction,
          walletId: transaction.walletId,
          seenAt: transaction.dateCreated,
          network: transaction.network,
          asset: transaction.asset,
          amount: transaction.amount,
          address: transaction.destinationAddress,
          type: 'WITHDRAW',
        });
      }
      history.sort((txA, txB) => (txA.seenAt < txB.seenAt ? 1 : -1));
      return history;
    };
    getHistory()
      .then(setHistory)
      .catch(setApiError)
      .finally(() => setIsLoaded(true));
  }, [role]);

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) return;
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  const sortBy = (key: string) => setActiveSort(`${key}--${requestSort(key)}`);

  const showCaret = (name: string) => {
    if (!activeSort) return;
    const [key, direction] = activeSort.split('--');
    if (name !== key) return;

    if (direction === 'ascending') return <ChevronUpIcon className="w-3" />;
    if (direction === 'descending') return <ChevronDownIcon className="w-3" />;
  };

  const customFilterByItem = (item: any) => {
    const anyTypeStatusTrue = Object.values(filter.type).some(value => value === true);

    let passesTypeFilter = true;

    // Apply approval status filter if any approval status filter is enabled
    if (anyTypeStatusTrue) {
      passesTypeFilter = filter.type[item.type];
    }

    return passesTypeFilter;
  };

  const pageNavigation = (
    <PageNavigation
      prev={prev}
      jump={jump}
      next={next}
      curr={page.current}
      max={page.max}
      displayPageNumber={displayPageNumber}
      setDisplayPageNumber={setDisplayPageNumber}
    />
  );

  function content() {
    if (!isLoaded) {
      return <Loading />;
    }

    if (apiError) {
      return (
        <div className="flex flex-col w-full items-center text-white text-sm justify-center py-4 pt-8">
          Error retrieving transactions.
          <button
            className="inline-flex items-start justify-start px-6 py-4 bg-slate-700 hover:bg-slate-600 focus:outline-none rounded text-white"
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </div>
      );
    }

    if (page.data().length === 0) {
      return (
        <div className="flex w-full items-center text-white text-sm justify-center py-4 pt-8">
          No transaction history
        </div>
      );
    }

    return (
      <div className="space-y-2">
        {pageNavigation}
        <table className="w-full">
          <thead>
            <tr className="text-xs text-left leading-none text-white font-semibold">
              <th>
                <button
                  onClick={() => sortBy('amount')}
                  className={`${getClassNamesFor('amount')} flex items-center`}
                >
                  <span>Amount</span>
                  <span>{showCaret('amount')}</span>
                </button>
              </th>
              <th>
                <button
                  onClick={() => sortBy('seenAt')}
                  className={`${getClassNamesFor('seenAt')} flex items-center`}
                >
                  <span>Date Seen</span>
                  <span>{showCaret('seenAt')}</span>
                </button>
              </th>
              <th>
                <span>Origin/Destination</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {page.data().map((d, idx) => (
              <HistoryListItem key={idx} data={d} searchQuery={searchQuery} />
            ))}
          </tbody>
        </table>
        <div className="flex items-center text-white text-sm justify-center">
          {page.from} - {page.to} of {sortedItems.length} transactions
        </div>
        {pageNavigation}
      </div>
    );
  }

  return (
    <div>
      <HistoryHeader
        history={filteredHistory}
        organizationVisibility={organizationVisibility}
        setOrganizationVisibility={setOrganizationVisibility}
      />
      <div className={`${baseCard} ${role === 'admins' && 'border-2 !border-green-500'}`}>
        <div className="space-x-2 float-right">
          <FilterEntries
            filter={filter}
            setFilter={setFilter}
            jump={jump}
            items={history}
            organizationVisibility={organizationVisibility}
            searchCategory={searchCategory}
            searchQuery={searchQuery}
            walletId={walletId}
            setFilteredItems={setFilteredHistory}
            itemDateField={'seenAt'}
            customFilterByItem={customFilterByItem}
            CustomFilterComponent={<FilterByType filter={filter} setFilter={setFilter} />}
          />
        </div>
        {content()}
      </div>
    </div>
  );
}
