import { useNavigate } from 'react-router-dom';
import { icon } from '../../../../config/tailwind.classnames';
import { hideModal } from '../../../../store/modal';
import { useDispatch } from 'react-redux';
import { handleTxApprovalRejection } from '../../../../store/transactions';
import { toast } from 'react-hot-toast';

const RejectTx = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    toast.loading('Submitting Approval...');
    dispatch(handleTxApprovalRejection('approve', id))
      .then(res => {
        dispatch(hideModal());

        if (res && res[0]?.error) {
          toast.dismiss();
          toast.error('Error with approving transaction.');
          return;
        }

        toast.dismiss();
        toast.success('Transaction Approved!');
        navigate('/dashboard/withdrawals');
      })
      .catch(e => console.log(e));
  };

  return (
    <div className="bg-black bg-opacity-40 w-[500px] h-fit rounded-xl text-white p-8 flex flex-col justify-between">
      <div className="flex w-full justify-evenly flex-col items-center">
        <h1 className="text-xl  text-center mb-5">Confirm Approval?</h1>
        <div className={`${icon.approve} ${icon.action} w-full`} onClick={handleSubmit}>
          Submit
        </div>
        <div
          className="w-fit mt-5 text-center text-sm cursor-pointer hover:text-red-500"
          onClick={() => dispatch(hideModal())}
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

export default RejectTx;
