import { useEffect, useState, useRef, FormEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import etana from '../../assets/Etana/white.svg';
import validator from 'validator';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { currentComponent, showModal } from '../../store/modal';
import ForgotPassword from './ForgotPassword';
import FirstLogin from './FirstLogin';
import MFA from './MFA';
import Cookies from 'universal-cookie';
import getSessionExpiration from '../../utils/getSessionExpiration';
import { storeUserSession } from '../../store/session';
import { AnyAction } from 'redux';

type ErrorState = Partial<{
  email: string;
  password: string;
  unauthorized: string;
}>;

export function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [errors, setErrors] = useState<ErrorState>({});

  const usernameInput = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = useMemo(() => new Cookies(), []);

  useEffect(() => {
    usernameInput.current?.focus();
    setErrors({});
    Auth.currentAuthenticatedUser()
      .then(async res => {
        if (res) {
          const token = res.getSignInUserSession().getAccessToken().getJwtToken();
          const expiration = await getSessionExpiration();
          if (!cookies.get('jwt_token')) {
            cookies.set('jwt_token', token, { path: '/', maxAge: expiration });
          }
          navigate('/dashboard');
        }
      })
      .catch(_ => console.info('INFO: User is not logged in.'));
  }, [cookies, navigate]);

  const inputClass = 'w-64 p-3 text-gray-300 bg-gray-800 rounded';

  async function handleLogin(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setErrors({});

    if (!username && !password) {
      setErrors(prev => ({ ...prev, email: 'Please enter your email and password.'
      }));
      return;
    }
    
    if (!username) {
      setErrors(prev => ({ ...prev, email: 'Please enter your email.' }));
      return;
    }

    if (!password) {
      setErrors(prev => ({ ...prev, password: 'Please enter your password.' }));
      return;
    }
    if (!validator.isEmail(username)) {
      setErrors(prev => ({ ...prev, email: 'Please enter a valid email address.' }));
      return;
    }
    const loadingMsg = toast.loading('Working...');
    try {
      const user = await Auth.signIn(username, password);
      const { challengeName } = user;
      toast.dismiss(loadingMsg);

      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(currentComponent(() => <FirstLogin userState={user} />));
        dispatch(showModal());
        setPassword('');
      } else if (challengeName === 'MFA_SETUP' || challengeName === 'SOFTWARE_TOKEN_MFA') {
        dispatch(currentComponent(() => <MFA userState={user} username={username} />));
        dispatch(showModal());
        setPassword('');
      } else {
        // TODO: correct typing on Redux thunks
        dispatch(storeUserSession(user, null) as unknown as AnyAction).then((res: any) => {
          if (res) {
            navigate('/authenticating');
          }
        });
      }
    } catch (e) {
      toast.dismiss();
      console.log(`Error with handleLogin: ${e}`);
      setErrors(prev => ({ ...prev, unauthorized: e.message }));
    }
  }

  return (
    <div className="flex justify-center pt-3 sm:pt-20">
      {forgotPassword ? (
        <ForgotPassword setForgotPassword={setForgotPassword} />
      ) : (
        <div className="grid p-24 bg-gradient-to-br from-gray-400/20 via-gray-200/25 to-gray-800/60 backdrop-blur-md rounded-lg shadow min-w-fit">
          <div className="pb-16 opacity-40">
            <img src={etana} alt="Etana Logo" className="max-w-[500px]" />
          </div>
          <ErrorTextBox
            email={errors.email}
            password={errors.password}
            unauthorized={errors.unauthorized}
          />
          <form
            autoComplete="off"
            className="grid text-white min-w-fit space-y-5"
            onSubmit={handleLogin}
          >
            <div className="flex items-center justify-between">
              <label>Email</label>
              <input
                className={inputClass}
                name="email"
                type="email"
                ref={usernameInput}
                data-lpignore="true"
                value={username}
                onChange={e => setUsername(e.target.value)}
              ></input>
            </div>
            <div className="flex items-center justify-between">
              <label>Password</label>
              <input
                className={inputClass}
                name="password"
                type="password"
                data-lpignore="true"
                value={password}
                onChange={e => setPassword(e.target.value)}
              ></input>
            </div>
            <button
              className="bg-slate-600 hover:bg-gray-500 bg-opacity-80 cursor-pointer p-5 rounded"
              type="submit"
            >
              Login
            </button>
          </form>
          <div className="flex justify-center">
            <div
              className="cursor-pointer text-xs text-gray-400 mt-5 hover:text-white"
              onClick={() => setForgotPassword(true)}
            >
              Forgot Password?
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function ErrorTextBox(state: ErrorState) {
  const { unauthorized, email, password } = state;
  if (!unauthorized && !email && !password) {
    return null;
  }
  return (
    <div className="pb-4">
      <div className="shadow-innerWhite text-white bg-red-200 bg-opacity-30 p-3 rounded border border-red-500 text-sm text-center">
        {unauthorized && <div>{unauthorized}</div>}
        {email && <div>{email}</div>}
        {password && <div>{password}</div>}
      </div>
    </div>
  );
}

export default Login;
