import { FormEvent, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

const EmailVerification = () => {
  const [emailState, setEmailState] = useState('');
  const [emailVerified, setEmailVerified] = useState<true | false>(false);
  const [inVerification, setInVerification] = useState<true | false>(false);
  const [verificationCode, setVerificationCode] = useState('');

  const [isLoaded, setIsLoaded] = useState(false);

  const [error, setError] = useState('');

  useEffect(() => {
    Auth.currentUserInfo()
      .then(res => {
        if (res) {
          const { email, email_verified } = res.attributes;
          setEmailState(email);
          setEmailVerified(email_verified);
        }
      })
      .then(() => setIsLoaded(true))
      .catch(e => {
        console.error(`Error with getting email info: ${e}`);
      });
  }, [emailVerified, inVerification]);

  const sendEmailVerification = () => {
    toast.loading('Working...');
    Auth.verifyCurrentUserAttribute('email')

      .then(() => setInVerification(true))
      .then(() => toast.dismiss())
      .catch(() => console.log('Error sending verification code to email.'));
  };

  const submitEmailVerification = (e: FormEvent) => {
    e.preventDefault();
    Auth.verifyCurrentUserAttributeSubmit('email', verificationCode)
      .then(() => setInVerification(false))
      .catch(err => setError(err?.message));
  };

  if (!isLoaded) {
    return (
      <span className="flex">
        <span className="text-gray-400">Loading...</span>
      </span>
    );
  }

  if (emailVerified) {
    return (
      <span className="flex space-x-2">
        <span>{emailState}</span>
        <span className="flex space-x-1">
          <CheckIcon className="w-4 text-green-400" />
          <span className="text-green-400">Verified</span>
        </span>
      </span>
    );
  }

  return (
    <div className="flex space-x-2">
      <div>{emailState}</div>
      {inVerification ? (
        <div className="text-white flex flex-col items-end justify-end text-sm mt-5 p-5 px-10 pb-2 bg-gray-500 bg-opacity-40 rounded-lg shadow-innerWhite">
          <form className="flex flex-col items-end" onSubmit={submitEmailVerification}>
            <label className="text-sm">
              Please enter the 6-digit code sent to your email address:
            </label>

            {error && <div className="text-red-400 text-xs mt-3">{error}</div>}
            <div className="flex items-center">
              <input
                type="text"
                maxLength={6}
                className="mt-3 mb-5 p-3 pl-4 bg-gray-700 tracking-[1em] w-42 text-center rounded-lg"
                value={verificationCode}
                placeholder="######"
                onChange={e => setVerificationCode(e.target.value)}
              ></input>
              <button
                type="submit"
                className="text-xs ml-3 h-11 p-3 rounded bg-green-700 hover:bg-green-600 bg-opacity-50"
              >
                Submit
              </button>
              <button
                type="button"
                className="text-xs ml-2 h-11 p-3 rounded bg-gray-700 hover:bg-gray-600 bg-opacity-50"
                onClick={() => setInVerification(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="text-red-500 flex justify-end text-xs mt-1">
          <XMarkIcon className="w-4" />
          <span className="px-2">Not Verified.</span>
          <span className="cursor-pointer hover:text-red-400" onClick={sendEmailVerification}>
            Verify now?
          </span>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
