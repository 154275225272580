import { BuildingLibraryIcon } from '@heroicons/react/24/outline';

export const AdminAccessBanner = ({ title }) => (
  <div className="flex text-green-500 space-x-2 items-center">
    <BuildingLibraryIcon className="w-8" />
    <span>{title}</span>
  </div>
);

export function adminAccessBanner(user) {
  if (user?.groups.includes('superadmins')) {
    return <AdminAccessBanner title="Super Admin" />;
  }
  if (user?.groups.includes('admins')) {
    return <AdminAccessBanner title="Admin" />;
  }
  if (user?.groups.includes('developers')) {
    return <AdminAccessBanner title="Developer" />;
  }
  return null;
}
