import { NetworkLogo } from './networks';

interface AssetAmountProps {
  asset: string;
  network: string;
  amount: number;
}

export function AssetAmount({ asset, network, amount }: AssetAmountProps) {
  return (
    <div className="flex items-center space-x-2">
      <span className="w-6">
        <NetworkLogo network={network} token={asset} size="s" />
      </span>
      <span>
        {amount} {asset.toUpperCase()}
      </span>
    </div>
  );
}
