type Urls = {
  apiUrl: string;
  superAdminUrl: string;
  adminUrl: string;
  transactionUrl: string;
  userUrl: string;
  apiClientUrl: string;
  notificationsUrl: string;
  walletUrl: string;
  createWallet: string;
  walletAssets: string;
  walletAddAsset: string;
  walletHideAsset: string;
  walletBalanceUrl: string;
  walletsByOwner: string;
  walletById: string;
  stakingUrl: string;
  polymeshUrl: string;
};

export enum enuEnvironment {
  local = 'local',
  uat = 'uat',
  production = 'production',
}

export const isMainnet = () => {
  return process.env.REACT_APP_ENVIRONMENT === enuEnvironment.production;
}

function environmentUrls(): Urls {
  let baseUrl: string;
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case enuEnvironment.local:
      baseUrl = 'http://localhost:3030';
      break;
    case enuEnvironment.uat:
      baseUrl = 'https://api.etanadigital.net';
      break;
    case enuEnvironment.production:
      baseUrl = 'https://api.etanadigital.io';
      break;
    default:
      baseUrl = 'https://api.etanadigital.net';
      break;
  }
  return {
    apiUrl: `${baseUrl}/v1/dashboard`,
    superAdminUrl: `${baseUrl}/v1/dashboard/superadmin`,
    adminUrl: `${baseUrl}/v1/dashboard/admin`,
    transactionUrl: `${baseUrl}/v1/dashboard/transactions`,
    userUrl: `${baseUrl}/v1/dashboard/users`,
    apiClientUrl: `${baseUrl}/v1/dashboard/api-clients`,
    notificationsUrl: `${baseUrl}/v1/dashboard/notifications`,
    walletUrl: `${baseUrl}/v1/dashboard/wallets`,
    createWallet: `${baseUrl}/v1/dashboard/wallets/create`,
    walletAssets: `${baseUrl}/v1/dashboard/wallets/assets`,
    walletAddAsset: `${baseUrl}/v1/dashboard/wallets/add-asset`,
    walletHideAsset: `${baseUrl}/v1/dashboard/wallets/hide-asset`,
    walletBalanceUrl: `${baseUrl}/v1/dashboard/wallets/balances`,
    walletsByOwner: `${baseUrl}/v1/dashboard/wallets/owner`,
    walletById: `${baseUrl}/v1/dashboard/wallets/id`,
    stakingUrl: `${baseUrl}/v1/dashboard/staking`,
    polymeshUrl: `${baseUrl}/v1/dashboard/polymesh`,
  };
}

export const urls = environmentUrls();

export const supportedNetworks: string[] = ['bitcoin', 'ethereum', 'avalanche', 'polymesh', 'stellar', 'solana', 'ripple', 'aptos', 'cardano', 'polkadot'];

export const organizations: { id: string; name: string }[] = [
  { id: 'ETANATRUST', name: 'ETANA TRUST' },
  { id: 'BDACS', name: 'BDACS' },
];
