import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import etana from '../../assets/Etana/white.svg';

import { Auth } from 'aws-amplify';

export function Authenticating() {
  const cookies = useMemo(() => new Cookies(), []);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentUserInfo().then(user => {
      if (cookies.get('jwt_token') || user) {
        navigate('/dashboard');
      } else {
        navigate('/login');
      }
    });
  }, [cookies, navigate]);

  return (
    <section className="h-screen">
      <div className="mx-auto flex justify-center lg:items-center h-full">
        <div className="pt-0 px-2 flex flex-col items-center justify-center">
          <img
            className="w-49 h-auto px-12 md:px-56 lg:px-80 xl:px-[32rem] animate-pulse"
            id="logo"
            src={etana}
            rel="preload"
            alt="Etana Digital"
          />
          <h1 className="text-2xl font-bold text-white animate-pulse">Authenticating...</h1>
        </div>
      </div>
    </section>
  );
}

export default Authenticating;
